import Axios from 'axios';

export const data = {

  get: ((bz_chart) => {
    let cpeb = data.reference_conception_palace_eb[bz_chart.eb[4]];
    let cphs = data.reference_conception_palace_hs[bz_chart.tg[4]];
    return cphs + cpeb;
  }),

  reference_gender: {
    "0": "Female",
    "1": "Male",
  },

  reference_gender_chinese: {
    "0": "女",
    "1": "男",
  },

  reference_60_jz_vs_hexagram: {
    "甲午": { "image_url": "/img/yijing/yijing_a01.png", "element": "金", "name": "Heaven", "name_ch": "乾为天", "description": "", "description_ch": "自强不息" },
    "癸巳": { "image_url": "/img/yijing/yijing_a43.png", "element": "金", "name": "Elimiating", "name_ch": "泽天夬", "description": "", "description_ch": "决而能和" },
    "辛巳": { "image_url": "/img/yijing/yijing_a14.png", "element": "木", "name": "Great Reward", "name_ch": "火天大有", "description": "", "description_ch": "顺天依时" },
    "己巳": { "image_url": "/img/yijing/yijing_a34.png", "element": "木", "name": "Great Strength", "name_ch": "雷天大壮", "description": "", "description_ch": "壮勿妄动" },
    "丁巳": { "image_url": "/img/yijing/yijing_a09.png", "element": "火", "name": "Small Livestock", "name_ch": "风天小畜", "description": "", "description_ch": "蓄养待进" },
    "乙巳": { "image_url": "/img/yijing/yijing_a05.png", "element": "火", "name": "Waiting", "name_ch": "水天需", "description": "", "description_ch": "守正待机" },
    "壬辰": { "image_url": "/img/yijing/yijing_a26.png", "element": "水", "name": "Big Livestock", "name_ch": "山天大畜", "description": "", "description_ch": "止而不止" },
    "庚辰": { "image_url": "/img/yijing/yijing_a11.png", "element": "水", "name": "Unity", "name_ch": "地天泰", "description": "", "description_ch": "应时而变" },
    "戊辰": { "image_url": "/img/yijing/yijing_a10.png", "element": "金", "name": "Tread", "name_ch": "天泽履", "description": "", "description_ch": "脚踏实地" },
    "丙辰": { "image_url": "/img/yijing/yijing_a58.png", "element": "金", "name": "Marsh", "name_ch": "兑为泽", "description": "", "description_ch": "刚内柔外" },
    "甲辰": { "image_url": "/img/yijing/yijing_a38.png", "element": "木", "name": "Opposition", "name_ch": "火泽睽", "description": "", "description_ch": "异中求同" },
    "癸卯": { "image_url": "/img/yijing/yijing_a54.png", "element": "木", "name": "Marrying Maiden", "name_ch": "雷泽归妹", "description": "", "description_ch": "立家兴业" },
    "辛卯": { "image_url": "/img/yijing/yijing_a61.png", "element": "火", "name": "Sincerity", "name_ch": "风泽中孚", "description": "", "description_ch": "诚信立身" },
    "己卯": { "image_url": "/img/yijing/yijing_a60.png", "element": "火", "name": "Regulate", "name_ch": "水泽节", "description": "", "description_ch": "万物有节" },
    "丁卯": { "image_url": "/img/yijing/yijing_a41.png", "element": "水", "name": "Decreasing", "name_ch": "山泽损", "description": "", "description_ch": "损益制衡" },
    "乙卯": { "image_url": "/img/yijing/yijing_a19.png", "element": "水", "name": "Arriving", "name_ch": "地泽临", "description": "", "description_ch": "教民保民" },
    "壬寅": { "image_url": "/img/yijing/yijing_a13.png", "element": "金", "name": "Fellowship", "name_ch": "天火同人", "description": "", "description_ch": "上下和同" },
    "庚寅2": { "image_url": "/img/yijing/yijing_a49.png", "element": "金", "name": "Reform", "name_ch": "泽火革", "description": "", "description_ch": "顺天应人" },
    "庚寅": { "image_url": "/img/yijing/yijing_a30.png", "element": "木", "name": "Fire", "name_ch": "离为火", "description": "", "description_ch": "附和依托" },
    "戊寅": { "image_url": "/img/yijing/yijing_a55.png", "element": "木", "name": "Abundance", "name_ch": "雷火丰", "description": "", "description_ch": "日中则斜" },
    "丙寅": { "image_url": "/img/yijing/yijing_a37.png", "element": "火", "name": "Family", "name_ch": "风火家人", "description": "", "description_ch": "诚威治业" },
    "甲寅": { "image_url": "/img/yijing/yijing_a63.png", "element": "火", "name": "Accompliashed", "name_ch": "水火既济", "description": "", "description_ch": "盛极将衰" },
    "癸丑": { "image_url": "/img/yijing/yijing_a22.png", "element": "水", "name": "Beauty", "name_ch": "山火贲", "description": "", "description_ch": "饰外扬质" },
    "辛丑": { "image_url": "/img/yijing/yijing_a36.png", "element": "水", "name": "Dimming Light", "name_ch": "地火明夷", "description": "", "description_ch": "晦而转明" },
    "己丑": { "image_url": "/img/yijing/yijing_a25.png", "element": "金", "name": "Without Wrongdoing", "name_ch": "天雷无妄", "description": "", "description_ch": "无妄而得" },
    "丁丑": { "image_url": "/img/yijing/yijing_a17.png", "element": "金", "name": "Following", "name_ch": "泽雷随", "description": "", "description_ch": "随时变通" },
    "乙丑": { "image_url": "/img/yijing/yijing_a21.png", "element": "木", "name": "Biting", "name_ch": "火雷噬嗑", "description": "", "description_ch": "刚柔相济" },
    "壬子": { "image_url": "/img/yijing/yijing_a51.png", "element": "木", "name": "Thunder", "name_ch": "震为雷", "description": "", "description_ch": "临危不乱" },
    "庚子": { "image_url": "/img/yijing/yijing_a42.png", "element": "火", "name": "Increasing", "name_ch": "风雷益", "description": "", "description_ch": "损上益下" },
    "戊子": { "image_url": "/img/yijing/yijing_a03.png", "element": "火", "name": "Beginning", "name_ch": "水雷屯", "description": "", "description_ch": "起始维艰" },
    "丙子": { "image_url": "/img/yijing/yijing_a27.png", "element": "水", "name": "Nourish", "name_ch": "山雷颐", "description": "", "description_ch": "纯正以养" },
    "甲子2": { "image_url": "/img/yijing/yijing_a24.png", "element": "水", "name": "Returning", "name_ch": "地雷复", "description": "", "description_ch": "寓动于顺" },
    "甲午2": { "image_url": "/img/yijing/yijing_a44.png", "element": "金", "name": "Meeting", "name_ch": "天风姤", "description": "", "description_ch": "天下有风" },
    "丙午": { "image_url": "/img/yijing/yijing_a28.png", "element": "金", "name": "Great Exeeding", "name_ch": "泽风大过", "description": "", "description_ch": "非常行动" },
    "戊午": { "image_url": "/img/yijing/yijing_a50.png", "element": "木", "name": "The Cauldron", "name_ch": "火风鼎", "description": "", "description_ch": "稳重图变" },
    "庚午": { "image_url": "/img/yijing/yijing_a32.png", "element": "木", "name": "Consistency", "name_ch": "雷风恒", "description": "", "description_ch": "恒心有成" },
    "壬午": { "image_url": "/img/yijing/yijing_a57.png", "element": "火", "name": "Wind", "name_ch": "巽为风", "description": "", "description_ch": "谦逊受益" },
    "乙未": { "image_url": "/img/yijing/yijing_a48.png", "element": "火", "name": "Well", "name_ch": "水风井", "description": "", "description_ch": "求贤若渴" },
    "丁未": { "image_url": "/img/yijing/yijing_a18.png", "element": "水", "name": "Poison", "name_ch": "山风蛊", "description": "", "description_ch": "振疲起衰" },
    "己未": { "image_url": "/img/yijing/yijing_a46.png", "element": "水", "name": "Rising", "name_ch": "地风升", "description": "", "description_ch": "柔顺谦虚" },
    "辛未": { "image_url": "/img/yijing/yijing_a06.png", "element": "金", "name": "Litigation", "name_ch": "天水讼", "description": "", "description_ch": "慎争戒讼" },
    "癸未": { "image_url": "/img/yijing/yijing_a47.png", "element": "金", "name": "Trap", "name_ch": "泽水困", "description": "", "description_ch": "困境求通" },
    "甲申": { "image_url": "/img/yijing/yijing_a64.png", "element": "木", "name": "Not Yet Accomplished", "name_ch": "火水未济", "description": "", "description_ch": "事业未竟" },
    "丙申": { "image_url": "/img/yijing/yijing_a40.png", "element": "木", "name": "Relief", "name_ch": "雷水解", "description": "", "description_ch": "柔道致治" },
    "戊申": { "image_url": "/img/yijing/yijing_a59.png", "element": "火", "name": "Dispersing", "name_ch": "风水涣", "description": "", "description_ch": "拯救涣散" },
    "庚申": { "image_url": "/img/yijing/yijing_a29.png", "element": "火", "name": "Water", "name_ch": "坎为水", "description": "", "description_ch": "行险用险" },
    "庚申2": { "image_url": "/img/yijing/yijing_a04.png", "element": "水", "name": "Bliss", "name_ch": "山水蒙", "description": "", "description_ch": "启蒙奋发" },
    "壬申": { "image_url": "/img/yijing/yijing_a15.png", "element": "水", "name": "Officer", "name_ch": "地山谦", "description": "", "description_ch": "内高外低" },
    "乙酉": { "image_url": "/img/yijing/yijing_a33.png", "element": "金", "name": "Retreat", "name_ch": "天山遁", "description": "", "description_ch": "遁世救世" },
    "丁酉": { "image_url": "/img/yijing/yijing_a31.png", "element": "金", "name": "Influence", "name_ch": "泽山咸", "description": "", "description_ch": "相互感应" },
    "己酉": { "image_url": "/img/yijing/yijing_a56.png", "element": "木", "name": "Travelling", "name_ch": "火山旅", "description": "", "description_ch": "依义顺时" },
    "辛酉": { "image_url": "/img/yijing/yijing_a62.png", "element": "木", "name": "Lessing Exceeding", "name_ch": "雷山小过", "description": "", "description_ch": "行动有度" },
    "癸酉": { "image_url": "/img/yijing/yijing_a53.png", "element": "火", "name": "Gradual Progress", "name_ch": "风山渐", "description": "", "description_ch": "渐进蓄德" },
    "甲戌": { "image_url": "/img/yijing/yijing_a39.png", "element": "火", "name": "Obstruction", "name_ch": "水山蹇", "description": "", "description_ch": "险阻在前" },
    "丙戌": { "image_url": "/img/yijing/yijing_a52.png", "element": "水", "name": "Mountain", "name_ch": "艮为山", "description": "", "description_ch": "动静适时" },
    "戊戌": { "image_url": "/img/yijing/yijing_a15.png", "element": "水", "name": "Humility", "name_ch": "地山谦", "description": "", "description_ch": "内高外低" },
    "庚戌": { "image_url": "/img/yijing/yijing_a12.png", "element": "金", "name": "Stagnation", "name_ch": "天地否", "description": "", "description_ch": "不交不通" },
    "壬戌": { "image_url": "/img/yijing/yijing_a45.png", "element": "金", "name": "Gathering", "name_ch": "泽地萃", "description": "", "description_ch": "荟萃聚集" },
    "乙亥": { "image_url": "/img/yijing/yijing_a35.png", "element": "木", "name": "Advancement", "name_ch": "火地晋", "description": "", "description_ch": "求进发展" },
    "丁亥": { "image_url": "/img/yijing/yijing_a16.png", "element": "木", "name": "Delight", "name_ch": "雷地豫", "description": "", "description_ch": "顺时依势" },
    "己亥": { "image_url": "/img/yijing/yijing_a20.png", "element": "火", "name": "Observation", "name_ch": "风地观", "description": "", "description_ch": "观下瞻上" },
    "辛亥": { "image_url": "/img/yijing/yijing_a08.png", "element": "火", "name": "Alliance", "name_ch": "水地比", "description": "", "description_ch": "诚信团结" },
    "癸亥": { "image_url": "/img/yijing/yijing_a23.png", "element": "水", "name": "Peel", "name_ch": "山地剥", "description": "", "description_ch": "顺势而止" },
    "甲子": { "image_url": "/img/yijing/yijing_a02.png", "element": "水", "name": "Earth", "name_ch": "坤为地", "description": "", "description_ch": "厚德载物" }
  },

  reference_60_pillar: [
    "甲子",
    "乙丑",
    "丙寅",
    "丁卯",
    "戊辰",
    "己巳",
    "庚午",
    "辛未",
    "壬申",
    "癸酉",
    "甲戌",
    "乙亥",
    "丙子",
    "丁丑",
    "戊寅",
    "己卯",
    "庚辰",
    "辛巳",
    "壬午",
    "癸未",
    "甲申",
    "乙酉",
    "丙戌",
    "丁亥",
    "戊子",
    "己丑",
    "庚寅",
    "辛卯",
    "壬辰",
    "癸巳",
    "甲午",
    "乙未",
    "丙申",
    "丁酉",
    "戊戌",
    "己亥",
    "庚子",
    "辛丑",
    "壬寅",
    "癸卯",
    "甲辰",
    "乙巳",
    "丙午",
    "丁未",
    "戊申",
    "己酉",
    "庚戌",
    "辛亥",
    "壬子",
    "癸丑",
    "甲寅",
    "乙卯",
    "丙辰",
    "丁巳",
    "戊午",
    "己未",
    "庚申",
    "辛酉",
    "壬戌",
    "癸亥"
  ],

  reference_60_pillar_tg: {
    "庚": 0,
    "辛": 1,
    "壬": 2,
    "癸": 3,
    "甲": 4,
    "乙": 5,
    "丙": 6,
    "丁": 7,
    "戊": 8,
    "己": 9,
  },

  reference_60_pillar_eb: {
    "申": 0,
    "酉": 1,
    "戌": 2,
    "亥": 3,
    "子": 4,
    "丑": 5,
    "寅": 6,
    "卯": 7,
    "辰": 8,
    "巳": 9,
    "午": 10,
    "未": 11,
  },

  find_year_60_pillar: ((year) => {
    let result = "";
    let year_mod = year % 12;
    let year_last = year % 10;

    result = Object.keys(data.reference_60_pillar_tg)[year_last];
    result += Object.keys(data.reference_60_pillar_eb)[year_mod];

    return result;
  }),

  reference_bz_10_god: {
    "甲": { "甲": "比肩", "乙": "劫财", "丙": "食神", "丁": "伤官", "戊": "偏财", "己": "正财", "庚": "七杀", "辛": "正官", "壬": "偏印", "癸": "正印", "  ": "  " },
    "乙": { "甲": "劫财", "乙": "比肩", "丙": "伤官", "丁": "食神", "戊": "正财", "己": "偏财", "庚": "正官", "辛": "七杀", "壬": "正印", "癸": "偏印", "  ": "  " },
    "丙": { "甲": "偏印", "乙": "正印", "丙": "比肩", "丁": "劫财", "戊": "食神", "己": "伤官", "庚": "偏财", "辛": "正财", "壬": "七杀", "癸": "正官", "  ": "  " },
    "丁": { "甲": "正印", "乙": "偏印", "丙": "劫财", "丁": "比肩", "戊": "伤官", "己": "食神", "庚": "正财", "辛": "偏财", "壬": "正官", "癸": "七杀", "  ": "  " },
    "戊": { "甲": "七杀", "乙": "正官", "丙": "偏印", "丁": "正印", "戊": "比肩", "己": "劫财", "庚": "食神", "辛": "伤官", "壬": "偏财", "癸": "正财", "  ": "  " },
    "己": { "甲": "正官", "乙": "七杀", "丙": "正印", "丁": "偏印", "戊": "劫财", "己": "比肩", "庚": "伤官", "辛": "食神", "壬": "正财", "癸": "偏财", "  ": "  " },
    "庚": { "甲": "偏财", "乙": "正财", "丙": "七杀", "丁": "正官", "戊": "偏印", "己": "正印", "庚": "比肩", "辛": "劫财", "壬": "食神", "癸": "伤官", "  ": "  " },
    "辛": { "甲": "正财", "乙": "偏财", "丙": "正官", "丁": "七杀", "戊": "正印", "己": "偏印", "庚": "劫财", "辛": "比肩", "壬": "伤官", "癸": "食神", "  ": "  " },
    "壬": { "甲": "食神", "乙": "伤官", "丙": "偏财", "丁": "正财", "戊": "七杀", "己": "正官", "庚": "偏印", "辛": "正印", "壬": "比肩", "癸": "劫财", "  ": "  " },
    "癸": { "甲": "伤官", "乙": "食神", "丙": "正财", "丁": "偏财", "戊": "正官", "己": "七杀", "庚": "正印", "辛": "偏印", "壬": "劫财", "癸": "比肩", "  ": "  " },
  },

  reference_earthly_branch_element: {
    "子": "壬",
    "丑": "己",
    "寅": "甲",
    "卯": "乙",
    "辰": "戊",
    "巳": "丁",
    "午": "丙",
    "未": "己",
    "申": "庚",
    "酉": "辛",
    "戌": "戊",
    "亥": "癸",
  },

  reference_life_gua: {
    "1": "坎",
    "2": "坤",
    "3": "震",
    "4": "巽",
    "5": "",
    "6": "乾",
    "7": "兑",
    "8": "艮",
    "9": "离"
  },

  reference_life_gua_english: {
    "1": "kan",
    "2": "kun",
    "3": "zhen",
    "4": "xun",
    "5": "",
    "6": "qian",
    "7": "dui",
    "8": "gen",
    "9": "li"
  },

  reference_hidden_branch: {
    "子": ["癸", "  ", "  "],
    "丑": ["己", "癸", "辛"],
    "寅": ["甲", "丙", "戊"],
    "卯": ["乙", "  ", "  "],
    "辰": ["戊", "乙", "癸"],
    "巳": ["丙", "戊", "庚"],
    "午": ["丁", "己", "  "],
    "未": ["己", "乙", "丁"],
    "申": ["庚", "戊", "壬"],
    "酉": ["辛", "  ", "  "],
    "戌": ["戊", "辛", "丁"],
    "亥": ["壬", "甲", "  "],
    "  ": ["  ", "  ", "  "],
  },

  reference_element_english: {
    "甲": "Jia",
    "乙": "Yi",
    "丙": "Bing",
    "丁": "Ding",
    "戊": "Wu",
    "己": "Ji",
    "庚": "Geng",
    "辛": "Xing",
    "壬": "Ren",
    "癸": "Gui",
    "  ": " "
  },

  reference_main_element_css: {
    "甲": "wood",
    "乙": "wood",
    "丙": "fire",
    "丁": "fire",
    "戊": "earth",
    "己": "earth",
    "庚": "metal",
    "辛": "metal",
    "壬": "water",
    "癸": "water",
    "  ": " "
  },

  reference_element_explaination_english: {
    "甲": "Yang Wood",
    "乙": "Yin Wood",
    "丙": "Yang Fire",
    "丁": "Yin Fire",
    "戊": "Yang Earth",
    "己": "Yin Earth",
    "庚": "Yang Metal",
    "辛": "Yin Metal",
    "壬": "Yang Water",
    "癸": "Yin Water",
    "  ": " "
  },

  reference_animal_ping_ying: {
    "子": "Zhi",
    "丑": "Chou",
    "寅": "Yin",
    "卯": "Mao",
    "辰": "Chen",
    "巳": "Si",
    "午": "Wu",
    "未": "Wei",
    "申": "Sheng",
    "酉": "You",
    "戌": "Xu",
    "亥": "Hai",
    "  ": " "
  },

  reference_animal_english: {
    "子": "Rat",
    "丑": "Ox",
    "寅": "Tiger",
    "卯": "Rabbit",
    "辰": "Dragon",
    "巳": "Snake",
    "午": "Horse",
    "未": "Goat",
    "申": "Monkey",
    "酉": "Rooster",
    "戌": "Dog",
    "亥": "Pig",
    "  ": " "
  },

  reference_horoscope_english: {
    "鼠": "Rat",
    "牛": "Ox",
    "虎": "Tiger",
    "兔": "Rabbit",
    "龙": "Dragon",
    "蛇": "Snake",
    "马": "Horse",
    "羊": "Goat",
    "猴": "Monkey",
    "鸡": "Rooster",
    "狗": "Dog",
    "猪": "Pig"
  },

  reference_nayin_english: {
    "海中金": "Sea Metal",
    "炉中火": "Furnace Fire",
    "大林木": "Forest Wood",
    "路旁土": "Road Earth",
    "剑锋金": "Sword Metal",
    "山头火": "Volcanic Fire",
    "涧下水": "Cave Water",
    "城头土": "Fortress Earth",
    "白蜡金": "Wax Metal",
    "杨柳木": "Willow Wood",
    "泉中水": "Stream Water",
    "屋上土": "Roof Tiles Earth",
    "霹雳火": "Lightning Fire",
    "松柏木": "Conifer Wood",
    "长流水": "River Water",
    "沙中金": "Sand Metal",
    "山下火": "Forest Fire",
    "平地木": "Meadow Wood",
    "壁上土": "Adobe Earth",
    "金箔金": "Precious Metal",
    "覆灯火": "Lamp Fire",
    "天河水": "Sky Water",
    "大驿土": "Highway Earth",
    "钗钏金": "Jewellery Metal",
    "桑柘木": "Mulberry Wood",
    "大溪水": "Rapids Water ",
    "沙中土": "Desert Earth",
    "天上火": "Sun Fire",
    "石榴木": "Pomegranate Wood",
    "大海水": "Ocean Water"
  },

  reference_day_officers_english: {
    "建": "Establish",
    "除": "Remove",
    "满": "Full",
    "平": "Balance",
    "定": "Stable",
    "执": "Initiate",
    "破": "Destruction",
    "危": "Danger",
    "成": "Sucess",
    "收": "Receive",
    "开": "Open",
    "闭": "Close"
  },

  reference_yellow_belt_star_english: {
    "司命": "Life Governor",
    "勾陈": "Grappling Hook",
    "青龙": "Green Dragon",
    "明堂": "Bright Hall",
    "朱雀": "Red Phoenix",
    "金匮": "Golden Lock",
    "天德": "Heavenly Moral",
    "白虎": "White Tiger",
    "玉堂": "Jade Hall",
    "天牢": "Heavenly Jail",
    "玄武": "Black Tortoise",
    "天刑": "Heavenly Punishment"
  },

  reference_yellow_belt_english: {
    "黑道": "Black Belt",
    "黄道": "Yellow Belt"
  },

  reference_luck_english: {
    "凶": "Inauspicious",
    "吉": "Auspicious"
  },

  reference_28_constellations_english: {
    "角": "Horn",
    "亢": "Neck",
    "氐": "Root",
    "房": "House",
    "心": "Heart",
    "尾": "Tail",
    "箕": "Basket",
    "斗": "Dipper",
    "牛": "Ox",
    "女": "Girl",
    "虚": "Emptiness",
    "危": "Danger",
    "室": "Camp",
    "壁": "Wall",
    "奎": "Legs",
    "娄": "Bond",
    "胃": "Stomach",
    "昴": "Pleiades",
    "毕": "Net",
    "觜": "Beak",
    "参": "Reference",
    "井": "Well",
    "鬼": "Ghost",
    "柳": "Willow",
    "星": "Star",
    "张": "Extend",
    "翼": "Wings",
    "轸": "Chariot",
  },

  reference_28_constellations_animal_english: {
    "蛟": "Water Dragon",
    "龙": "Dragon",
    "貉": "Raccoon",
    "兔": "Rabbit",
    "狐": "Fox",
    "虎": "Tiger",
    "豹": "Leopard",
    "犴": "Moose",
    "羊": "Sheep",
    "獐": "Roe",
    "马": "Horse",
    "鹿": "Deer",
    "蛇": "Snake",
    "蚓": "Worm",
    "狼": "Wolf",
    "狗": "Dog",
    "彘": "Pheasant", // Got conflict. 胃土彘 or 胃土雉
    "鸡": "Chicken",
    "乌": "Bird",
    "猴": "Monkey",
    "猿": "Ape",
    "獬": "Unicorn",
    "牛": "Ox",
    "蝠": "Bat",
    "鼠": "Rat",
    "燕": "Swallow",
    "猪": "Pig",
    "獝": "Otter" // need confirmation
  },

  reference_24_solar_terms_english: {
    "立春": "Spring Commences",
    "雨水": "Spring Showers",
    "惊蛰": "Insects Waken",
    "春分": "Vernal Equinox",
    "清明": "Bright and Clear",
    "谷雨": "Corn Rain",
    "立夏": "Summer Commences",
    "小满": "Corn Forms",
    "芒种": "Corn on Ear",
    "夏至": "Summer Solstice",
    "小暑": "Moderate Heat",
    "大暑": "Great Heat",
    "立秋": "Autumn Commences",
    "处暑": "End of Heat",
    "白露": "White Dew",
    "秋分": "Autumnal Equinox",
    "寒露": "Cold Dew",
    "霜降": "Frost",
    "立冬": "Winter Solstice",
    "小雪": "Light Snow",
    "大雪": "Heavy Snow",
    "冬至": "Winter Solstice",
    "小寒": "Moderate Cold",
    "大寒": "Severe Cold"
  },

  reference_yi_jing_english: {
    "乾": "Qian",
    "兑": "Dui",
    "离": "Li",
    "震": "Zhen",
    "巽": "Xun",
    "坎": "Kan",
    "艮": "Gen",
    "坤": "Kun"
  },

  reference_7_zheng_english: {
    "日": "Sun",
    "月": "Moon",
    "火": "Fire",
    "水": "Water",
    "木": "Wood",
    "金": "Metal",
    "土": "Earth"
  },

  reference_direction_english: {
    "n": "NORTH",
    "ne": "NORTHEAST",
    "e": "EAST",
    "se": "SOUTHEAST",
    "s": "SOUTH",
    "sw": "SOUTHWEST",
    "w": "WEST",
    "nw": "NORTHWEST",
    "c": "CENTER"
  },

  reference_direction_index: ["n", "ne", "e", "se", "s", "sw", "w", "nw", "c"],

  reference_direction_chinese_to_english: {
    "正北": "NORTH",
    "北": "NORTH",
    "东北": "NORTHEAST",
    "正东": "EAST",
    "东": "EAST",
    "东南": "SOUTHEAST",
    "正南": "SOUTH",
    "南": "SOUTH",
    "西南": "SOUTHWEST",
    "正西": "WEST",
    "西": "WEST",
    "西北": "NORTHWEST",
    "正中": "CENTER"
  },

  reference_lunar_phase_english: {
    "朔": "New Moon", // Main
    "既朔": "Waxing Crescent",
    "蛾眉新": "Waxing Crescent",
    "蛾眉": "Waxing Crescent", // Main
    "夕月": "Waxing Crescent",
    "上弦": "First Quarter", // Main
    "九夜": "Waxing Gibbous",
    "宵": "Waxing Gibbous",
    "渐盈凸": "Waxing Gibbous", // Main
    "小望": "Waxing Gibbous",
    "望": "Full Moon", // Main
    "既望": "Waning Gibbous",
    "立待": "Waning Gibbous",
    "居待": "Waning Gibbous",
    "寝待": "Waning Gibbous",
    "更待": "Waning Gibbous",
    "渐亏凸": "Waning Gibbous", // Main
    "下弦": "Third Quarter", // Main
    "有明": "Waning Crescent",
    "蛾眉残": "Waning Crescent",
    "残": "Waning Crescent", // Main
    "晓": "Waning Crescent",
    "晦": "Dark Moon" // Main
  },

  reference_direction_chinese: {
    "n": "正北",
    "ne": "东北",
    "e": "正东",
    "se": "东南",
    "s": "正南",
    "sw": "西南",
    "w": "正西",
    "nw": "西北",
    "c": "正中"
  },

  reference_direction_chinese_to_index: {
    "正北": "n",
    "北": "n",
    "东北": "ne",
    "正东": "e",
    "东": "e",
    "东南": "se",
    "正南": "s",
    "南": "s",
    "西南": "sw",
    "正西": "w",
    "西": "w",
    "西北": "nw",
    "正中": "c",
    "中": "c"
  },

  reference_element_clash: {
    "甲": "庚",
    "乙": "辛",
    "丙": "壬",
    "丁": "癸",
    "戊": "甲",
    "己": "乙",
    "庚": "丙",
    "辛": "丁",
    "壬": "戊",
    "癸": "己",
  },

  reference_animal_clash: {
    "子": "午",
    "丑": "未",
    "寅": "申",
    "卯": "酉",
    "辰": "戌",
    "巳": "亥",
    "午": "子",
    "未": "丑",
    "申": "寅",
    "酉": "卯",
    "戌": "辰",
    "亥": "巳"
  },

  reference_10_god_english: {
    "比肩": "F",
    "劫财": "RW",
    "食神": "EG",
    "伤官": "HO",
    "偏财": "IW",
    "正财": "DW",
    "七杀": "7K",
    "正官": "DO",
    "偏印": "IR",
    "正印": "DR",
    "  ": "  "
  },

  reference_10_god_icon: {
    "比肩": "👥",
    "劫财": "🔪",
    "食神": "🍜",
    "伤官": "⛑",
    "偏财": "🎰",
    "正财": "💵",
    "七杀": "7️⃣",
    "正官": "🎩",
    "偏印": "💎",
    "正印": "🎓",
    "  ": "  "
  },

  reference_10_god_from_lunar: {
    "比": "比肩",
    "劫": "劫财",
    "食": "食神",
    "傷": "伤官",
    "才": "偏财",
    "財": "正财",
    "殺": "七杀",
    "官": "正官",
    "梟": "偏印",
    "印": "正印",
    "  ": "  "
  },

  reference_animal_icon: {
    "子": "🐁",
    "丑": "🐂",
    "寅": "🐅",
    "卯": "🐇",
    "辰": "🐉",
    "巳": "🐍",
    "午": "🐎",
    "未": "🐑",
    "申": "🐒",
    "酉": "🐓",
    "戌": "🐕",
    "亥": "🐖",
    "  ": "  "
  },

  reference_direction_icon: {
    "n": "⬆️",
    "ne": "↗️",
    "e": "➡️",
    "se": "↘️",
    "s": "⬇️",
    "sw": "↙️",
    "w": "⬅️",
    "nw": "↖️",
    "c": "⚫️"
  },

  reference_element_icon: {
    "甲": "🌳",
    "乙": "🌱",
    "丙": "🔆",
    "丁": "🕯",
    "戊": "⛰",
    "己": "🏜",
    "庚": "🪓",
    "辛": "💍",
    "壬": "🌊",
    "癸": "💧",
    "  ": "  ",
  },

  reference_yellow_belt_icon: {
    "黑道": "⬛️",
    "黄道": "🟨"
  },

  reference_lunar_phase_icon: {
    "朔": "🌑", // Main
    "既朔": "🌒",
    "蛾眉新": "🌒",
    "蛾眉": "🌒", // Main
    "夕月": "🌒",
    "上弦": "🌓", // Main
    "九夜": "🌔",
    "宵": "🌔",
    "渐盈凸": "🌔", // Main
    "小望": "🌔",
    "望": "🌕", // Main
    "既望": "🌖",
    "立待": "🌖",
    "居待": "🌖",
    "寝待": "🌖",
    "更待": "🌖",
    "渐亏凸": "🌖", // Main
    "下弦": "🌗", // Main
    "有明": "🌘",
    "蛾眉残": "🌘",
    "残": "🌘", // Main
    "晓": "🌘",
    "晦": "🌘" // Main // should be 🌑 but to differentiate chu 1 and last day.
  },

  reference_day_officers_icon: {
    "建": "🏗",
    "除": "🧹",
    "满": "🈵",
    "平": "⚖️",
    "定": "🧘‍♀️",
    "执": "🚀",
    "破": "🧨",
    "危": "🚨",
    "成": "🎉",
    "收": "📥",
    "开": "⭕️",
    "闭": "🚫"
  },

  reference_luck_icon: {
    "凶": "❌",
    "吉": "✅"
  },

  reference_7_zheng_icon: {
    "日": "🌞",
    "月": "🌙",
    "火": "🔥",
    "水": "💦",
    "木": "🌴",
    "金": "🔩",
    "土": "🏕"
  },

  reference_fy_icon: {
    "FanYin": "🔀",
    "FuYin": "🔂",
    "": ""
  },

  reference_yinyang_english: {
    "阴": "Yin",
    "阳": "Yang",
    "": ""
  },

  reference_yellow_belt_star_icon: {
    "司命": "👨‍⚖️",
    "勾陈": "勾",
    "青龙": "🐲",
    "明堂": "🌟",
    "朱雀": "雀",
    "金匮": "🔒",
    "天德": "德",
    "白虎": "🐯",
    "玉堂": "玉",
    "天牢": "牢",
    "玄武": "🐢",
    "天刑": "刑"
  },

  suit_and_avoid_icon: {
    "1": "🚀", // commerce new  business
    "嫁娶": "💍", // get married
    "破土": "⛏", // break ground
    "2": "🤝", // meeting
    "3": "🛠", // renovation
    "4": "📝", // sign contract
    "出行": "🛫", // travelling
    "移徙": "🚚", // move house
  },

  reference_28_constellations_icon: {
    "角": "🦄",
    "亢": "亢",
    "氐": "🥕",
    "房": "🏡",
    "心": "❤",
    "尾": "尾",
    "箕": "🧺",
    "斗": "斗",
    "牛": "🐮",
    "女": "👩",
    "虚": "虚",
    "危": "⚠️",
    "室": "⛺️",
    "壁": "壁",
    "奎": "🦵",
    "娄": "娄",
    "胃": "胃",
    "昴": "✨",
    "毕": "毕",
    "觜": "觜",
    "参": "参",
    "井": "井",
    "鬼": "👻",
    "柳": "柳",
    "星": "⭐️",
    "张": "张",
    "翼": "🕊",
    "轸": "轸"
  },

  reference_28_constellations_animal_icon: {
    "蛟": "蛟",
    "龙": "🐉",
    "貉": "🦝",
    "兔": "🐇",
    "狐": "🦊",
    "虎": "🐅",
    "豹": "🐆",
    "犴": "犴",
    "羊": "🐑",
    "獐": "獐",
    "马": "🐎",
    "鹿": "🦌",
    "蛇": "🐍",
    "蚓": "🐛",
    "狼": "🐺",
    "狗": "🦮",
    "彘": "彘", // Got conflict. 胃土彘 or 胃土雉
    "鸡": "🐓",
    "乌": "🦜",
    "猴": "🐒",
    "猿": "🦧",
    "獬": "🦄",
    "牛": "🐂",
    "蝠": "🦇",
    "鼠": "🐀",
    "燕": "燕",
    "猪": "🐖",
    "獝": "🦦" // need confirmation
  },

  reference_astro_sign_icon: {
    "aries": "♈️",
    "taurus": "♉️",
    "gemini": "♊️",
    "cancer": "♋️",
    "leo": "♌️",
    "virgo": "♍️",
    "libra": "♎️",
    "scorpio": "♏️",
    "sagittarius": "♐️",
    "capricorn": "♑️",
    "aquarius": "♒️",
    "pisces": "♓️"
  },

  reference_life_gua_icon: {
    "1": "☵",
    "2": "☷",
    "3": "☳",
    "4": "☴",
    "5": "",
    "6": "☰",
    "7": "☱",
    "8": "☶",
    "9": "☲"
  },

  reference_month: [
    '', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ],

  reference_week_day: [
    'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'
  ],

  reference_month_full: [
    '', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],

  reference_week_day_full: [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
  ],

  reference_number_chinese: [
    '零', '一', '二', '三', '四', '五', '六', '七', '八', '九',
    '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九',
    '二十', '二十一', '二十二', '二十三', '二十四', '二十五', '二十六', '二十七', '二十八', '二十九',
    '三十', '三十一', '三十二', '三十三', '三十四', '三十五', '三十六', '三十七', '三十八', '三十九',
  ],

  reference_week_day_chinese: [
    '日', '一', '二', '三', '四', '五', '六', '日'
  ],

  reference_palace_element: {
    'sw': '土',
    'ne': '土',
    'e': '木',
    'se': '木',
    'n': '水',
    's': '火',
    'w': '金',
    'nw': '金',
  },

  reference_stem_element: {
    "甲": "木",
    "乙": "木",
    "丙": "火",
    "丁": "火",
    "戊": "土",
    "己": "土",
    "庚": "金",
    "辛": "金",
    "壬": "水",
    "癸": "水",
  },

  reference_door_element: {
    '生': '土',
    '傷': '木',
    '杜': '木',
    '休': '水',
    '景': '火',
    '開': '金',
    '驚': '金',
    '死': '土',
  },

  reference_star_element: {
    '輔': '木',
    '英': '火',
    '芮': '土',
    '冲': '木',
    '柱': '金',
    '任': '土',
    '蓬': '水',
    '心': '金',
  },

  reference_deity_element: {
    '符': '土',
    '天': '金',
    '地': '土',
    '陰': '金',
    '合': '木',
    '陳': '土',
    '虎': '金',
    '雀': '火',
    '玄': '水',
    '蛇': '火',
  },

  reference_element_interaction: {
    '金金': 's',
    '木木': 's',
    '水水': 's',
    '火火': 's',
    '土土': 's',
    '金水': 'p',
    '木火': 'p',
    '水木': 'p',
    '火土': 'p',
    '土金': 'p',
    '水金': 'w',
    '火木': 'w',
    '木水': 'w',
    '土火': 'w',
    '金土': 'w',
    '金木': 'co',
    '木土': 'co',
    '水火': 'co',
    '火金': 'co',
    '土水': 'co',
    '金火': 'ci',
    '木金': 'ci',
    '水土': 'ci',
    '火水': 'ci',
    '土木': 'ci',
  },

  reference_interaction_icon: {
    's': '=',
    'p': '➡️',
    'w': '⬅️',
    'co': '❌➡️',
    'ci': '❌⬅️',
  },

  reference_deity_english: {
    '符': 'chief',
    '天': 'nine heaven',
    '地': 'nine earth',
    '陰': 'moon',
    '合': 'harmony',
    '陳': 'hook',
    '虎': 'tiger',
    '雀': 'phoenix',
    '玄': 'tortoise',
    '蛇': 'snake',
  },

  reference_deity_icon: {
    '符': '👑',
    '天': '🌤',
    '地': '🌏',
    '陰': '🌕',
    '合': '🈴',
    '陳': '🔱',
    '虎': '🐅',
    '雀': '🦚',
    '玄': '🐢',
    '蛇': '🐍',
  },

  reference_star_english: {
    '蓬': 'grass',
    '任': 'ambasador',
    '冲': 'destructor',
    '辅': 'assistant',
    '輔': 'assistant',
    '英': 'hero',
    '芮': 'grain',
    '柱': 'pillar',
    '心': 'column',
    '禽': 'destroyer'
  },

  reference_star_icon: {
    '蓬': '🌿',
    '任': '🇺🇳',
    '冲': '💣',
    '辅': '🧑‍🏫',
    '輔': '🧑‍🏫',
    '英': '🦸',
    '芮': '🌾',
    '柱': '💈',
    '心': '❤️',
    '禽': '🕊'
  },

  reference_door_english: {
    '開': 'open',
    '休': 'rest',
    '生': 'life',
    '傷': 'harm',
    '杜': 'delusion',
    '驚': 'fear',
    '景': 'scenery',
    '死': 'death'
  },

  reference_door_icon: {
    '開': '👐',
    '休': '💤',
    '生': '🍼',
    '傷': '🔪',
    '杜': '🪄',
    '驚': '😱',
    '景': '🏞',
    '死': '☠️'
  },

  reference_element_css: {
    '金': 'qm_metal_text',
    '木': 'qm_wood_text',
    '水': 'qm_water_text',
    '火': 'qm_fire_text',
    '土': 'qm_earth_text',
  },

  displayTime: ((h, mi, s) => {
    return ((h < 10) ? "0" : "") + h + ":" + ((mi < 10) ? "0" : "") + mi;
    //  + ":" + ((s < 10) ? "0" : "") + s;
  }),

  convertSuitsToEnglish: ((suits) => {
    let suitsEnglish = [];
    let suitsArray = suits.split(',');
    suitsArray.forEach(s => {
      switch (s) {
        case '纳采':
          suitsEnglish.push('Betrothal');
          break;
        case '订盟':
          suitsEnglish.push('Make An Alliance');
          break;
        case '祭祀':
          suitsEnglish.push('Praying Worshiping');
          break;
        case '求嗣':
          suitsEnglish.push('Ask for Heir');
          break;
        case '出火':
          suitsEnglish.push('Move Deity Statue/Shrine');
          break;
        case '塑绘':
          suitsEnglish.push('Start a Painting in a Temple or Carve Deity or Human Statue');
          break;
        case '裁衣':
          suitsEnglish.push('Groom Deliver Wine/Blue Clothe and Silver to Bridge via matchmaker/1 month before Wedding');
          break;
        case '会亲友':
          suitsEnglish.push('Social Events');
          break;
        case '入学':
          suitsEnglish.push('Start School');
          break;
        case '拆卸':
          suitsEnglish.push('Demolition');
          break;
        case '扫舍':
          suitsEnglish.push('Sweep away Dust on Roof or Dead Corner of House');
          break;
        case '造仓':
          suitsEnglish.push('Build or Repair Warehouse');
          break;
        case '挂匾':
          suitsEnglish.push('Hang Signboard or Plaque');
          break;
        case '掘井':
          suitsEnglish.push('Dig for Sewer/Fish Pond/Well');
          break;
        case '开池':
          suitsEnglish.push('Dig for Well/Pond');
          break;
        case '结网':
          suitsEnglish.push('Netting');
          break;
        case '栽种':
          suitsEnglish.push('Planting/Seeding');
          break;
        case '纳畜':
          suitsEnglish.push('Herding, Buying of Livestock/Poultry/Pets');
          break;
        case '破土':
          suitsEnglish.push('Ground Breaking for Tomb/Grave');
          break;
        case '修坟':
          suitsEnglish.push('Repair Tomb/Grave');
          break;
        case '立碑':
          suitsEnglish.push('Monument');
          break;
        case '安葬':
          suitsEnglish.push('Burial');
          break;
        case '入殓':
          suitsEnglish.push('Enshrined');
          break;
        case '祈福':
          suitsEnglish.push('Praying for Blessing');
          break;
        case '嫁娶':
          suitsEnglish.push('Weddings/Marriage');
          break;
        case '造庙':
          suitsEnglish.push('Construction of Temple');
          break;
        case '安床':
          suitsEnglish.push('Bed Placement');
          break;
        case '谢土':
          suitsEnglish.push('Ceremony Marked End of Construction');
          break;
        case '开仓':
          suitsEnglish.push('Open Warehouse');
          break;
        case '移徙':
          suitsEnglish.push('Relocate/Move House (Not New)');
          break;
        case '入宅':
          suitsEnglish.push('Move into a New House');
          break;
        case '诸事不宜':
          suitsEnglish.push('Not good for any activities');
          break;
        case '解除':
          suitsEnglish.push('Removal/Cleaning House/Resolve Disasters');
          break;
        case '馀事勿取':
          suitsEnglish.push('Do not do anything other than stated');
          break;
        case '沐浴':
          suitsEnglish.push('Bath, Cleansing Body');
          break;
        case '冠笄':
          suitsEnglish.push('Coming-of-Age Ceremony');
          break;
        case '修造':
          suitsEnglish.push('Renovation');
          break;
        case '动土':
          suitsEnglish.push('Ground Breaking for House');
          break;
        case '除服':
          suitsEnglish.push('Taking off mourning clothes');
          break;
        case '成服':
          suitsEnglish.push('Wearing mourning clothes');
          break;
        case '移柩':
          suitsEnglish.push('Moving coffin out');
          break;
        case '启钻':
          suitsEnglish.push('Picking Bone');
          break;
        case '伐木':
          suitsEnglish.push('Cut Tree to Make Roof Beams');
          break;
        case '纳财':
          suitsEnglish.push('Make Money');
          break;
        case '交易':
          suitsEnglish.push('Business Deal/Transaction');
          break;
        case '立券':
          suitsEnglish.push('Enter into Contracts');
          break;
        case '作灶':
          suitsEnglish.push('Installation/Shift of Kitchen Stove');
          break;
        case '上梁':
          suitsEnglish.push('Installing of Roof');
          break;
        case '出行':
          suitsEnglish.push('Travelling');
          break;
        case '斋醮':
          suitsEnglish.push('Fasting Ceremony Before Build Temple');
          break;
        case '竖柱':
          suitsEnglish.push('Erection of Building Pillar');
          break;
        case '合帐':
          suitsEnglish.push('Making of Mosquito Nets/Placement of Curtains');
          break;
        case '造畜稠':
          suitsEnglish.push('Construction of Huts for Poultry and Livestock');
          break;
        case '起基':
          suitsEnglish.push('First Time Excavate Soil for Construction');
          break;
        case '进人口':
          suitsEnglish.push('Adopting Children or Godsons/Goddaughters');
          break;
        case '修饰垣墙':
          suitsEnglish.push('Repair Wall/House/Restructure House/Renovation');
          break;
        case '平治道涂':
          suitsEnglish.push('Paving Road');
          break;
        case '开光':
          suitsEnglish.push('Consecrate');
          break;
        case '安香':
          suitsEnglish.push('Placing God of Land or Ancester');
          break;
        case '治病':
          suitsEnglish.push('Illness Treatment');
          break;
        case '开市':
          suitsEnglish.push('Start Trading');
          break;
        case '赴任':
          suitsEnglish.push('Taking Office');
          break;
        case '捕捉':
          suitsEnglish.push('Pest Control');
          break;
        case '安门':
          suitsEnglish.push('Install Door for House or Room');
          break;
        case '盖屋':
          suitsEnglish.push('Install Roof for House');
          break;
        case '破屋':
          suitsEnglish.push('Demolition/Demolishing Houses or Walls');
          break;
        case '坏垣':
          suitsEnglish.push('Demolishing of Houses or Walls');
          break;
        case '纳婿':
          suitsEnglish.push('Groom Marry into Bride Family');
          break;
        case '畋猎':
          suitsEnglish.push('Hunting');
          break;
        case '安碓磑':
          suitsEnglish.push('Install Stone Mill');
          break;
        case '安机械':
          suitsEnglish.push('Install and Test Machinery/Spinning Wheel');
          break;
        case '造车器':
          suitsEnglish.push('Delivery of New Vehicles');
          break;
        case '经络':
          suitsEnglish.push('Weaving/Collect Silkworm/Install Spinning Wheel/Install Machinery');
          break;
        case '教牛马':
          suitsEnglish.push('Training Cattle and Horses');
          break;
        case '置产':
          suitsEnglish.push('Purchasing Property/Personal Goods');
          break;
        case '作梁':
          suitsEnglish.push('Chop Tree to Make Roof Beams');
          break;
        case '取渔':
          suitsEnglish.push('Selling Raised Fish');
          break;
        case '补垣':
          suitsEnglish.push('Plugging Holes/Pits/Covering Walls');
          break;
        case '探病':
          suitsEnglish.push('Visit the Sick');
          break;
        case '无':
          suitsEnglish.push('None');
          break;
        case '牧养':
          suitsEnglish.push('Shed Livestock');
          break;
        case '开厕':
          suitsEnglish.push('Construction of Toilet');
          break;
        case '习艺':
          suitsEnglish.push('Learn a New Skill/Enter of Apprenticeship');
          break;
        case '求医':
          suitsEnglish.push('Seeking Treatment for Chronic Illness or Surgery');
          break;
        case '塞穴':
          suitsEnglish.push('Blocking a Cave or Ant Den/etc');
          break;
        case '放水':
          suitsEnglish.push('Pour/Release Washed Pond/Aquarium Water ');
          break;
        case '酬神':
          suitsEnglish.push('Votive/Thank God');
          break;
        case '问名':
          suitsEnglish.push('The Second Ceremony of the Six Wedding Ceremony in Ancient Times');
          break;
        case '针灸':
          suitsEnglish.push('Acupuncture');
          break;
        case '开渠':
          suitsEnglish.push('Open Rivers or Ditches');
          break;
        case '词讼':
          suitsEnglish.push('Litigation');
          break;
        case '分居':
          suitsEnglish.push('Separation');
          break;
        case '行丧':
          suitsEnglish.push('Attend Funeral and Give Condolence');
          break;
        case '理发':
          suitsEnglish.push('Haircut');
          break;
        case '雇庸':
          suitsEnglish.push('Maid/Helper Hiring');
          break;
        case '架马':
          suitsEnglish.push('Install Beam in Roof Top');
          break;
        case '开柱眼':
          suitsEnglish.push('Making of Pillars');
          break;
        case '合脊':
          suitsEnglish.push('Closing Roof and other Roof Work');
          break;
        case '合寿木':
          suitsEnglish.push('Making of Coffin');
          break;
        case '定磉':
          suitsEnglish.push('Lay Foundations Below a Column');
          break;
        case '出货财':
          suitsEnglish.push('Shippment/Sales/Money Lending');
          break;
        case '筑堤':
          suitsEnglish.push('Construction or Reinforcement of River or Seawall');
          break;
        case '雕刻':
          suitsEnglish.push('Sculpture');
          break;
        case '开生坟':
          suitsEnglish.push('Groundbreaking for Construction of a Tomb');
          break;
        case '造船':
          suitsEnglish.push('Constrution of Ship/Boat');
          break;
        case '断蚁':
          suitsEnglish.push('Block/Close Ant Den');
          break;
        case '造桥':
          suitsEnglish.push('Construction of Bridge');
          break;
        case '整手足甲':
          suitsEnglish.push('First Nail Cutting for Newborn');
          break;
        case '割蜜':
          suitsEnglish.push('Harvest Honey/Make Wine/Keep Bee');
          break;
        case '普渡':
          suitsEnglish.push('Deliverance');
          break;
        case '乘船':
          suitsEnglish.push('Travel by Boat');
          break;
        case '订婚':
          suitsEnglish.push('Wedding Engagement');
          break;
        case '求财':
          suitsEnglish.push('Worship for Wealth');
          break;
        case '见贵':
          suitsEnglish.push('Meet Nobleman');
          break;
        case '':
          suitsEnglish.push('');
          break;

        default:
          suitsEnglish.push(s);
          break;
      }
    });

    return suitsEnglish.join(',\n');
  }
  ),

  // ---------
  // Life Gua
  // ---------

  reference_life_gua_8_mansions: {
    // East Group
    1: { "生气": "se", "天医": "e", "延年": "s", "伏位": "n", "祸害": "w", "五鬼": "ne", "六煞": "nw", "绝命": "sw" },
    3: { "生气": "s", "天医": "n", "延年": "se", "伏位": "e", "祸害": "sw", "五鬼": "nw", "六煞": "ne", "绝命": "w" },
    4: { "生气": "n", "天医": "s", "延年": "e", "伏位": "se", "祸害": "nw", "五鬼": "sw", "六煞": "w", "绝命": "ne" },
    9: { "生气": "e", "天医": "se", "延年": "n", "伏位": "s", "祸害": "ne", "五鬼": "w", "六煞": "sw", "绝命": "nw" },
    // West Group
    2: { "生气": "ne", "天医": "w", "延年": "nw", "伏位": "sw", "祸害": "e", "五鬼": "se", "六煞": "s", "绝命": "n" },
    6: { "生气": "w", "天医": "ne", "延年": "sw", "伏位": "nw", "祸害": "se", "五鬼": "e", "六煞": "n", "绝命": "s" },
    7: { "生气": "nw", "天医": "sw", "延年": "ne", "伏位": "w", "祸害": "n", "五鬼": "s", "六煞": "se", "绝命": "e" },
    8: { "生气": "sw", "天医": "nw", "延年": "w", "伏位": "ne", "祸害": "s", "五鬼": "n", "六煞": "e", "绝命": "se" }
  },

  // ---------
  // Esther BAZI Auxiliary Conversion
  // ---------

  reference_peach_blossom: {
    "子": "酉",
    "丑": "午",
    "寅": "卯",
    "卯": "子",
    "辰": "酉",
    "巳": "午",
    "午": "卯",
    "未": "子",
    "申": "酉",
    "酉": "午",
    "戌": "卯",
    "亥": "子",
    "  ": "  "
  },

  reference_peach_blossom_list: [
    "酉", "午", "卯", "子"
  ],

  isPeachBlossom: ((eb) => {
    return (eb === "酉") ||
      (eb === "午") ||
      (eb === "卯") ||
      (eb === "子");
  }),

  reference_strangle_star: {
    "子": "戌",
    "丑": "亥",
    "寅": "子",
    "卯": "丑",
    "辰": "寅",
    "巳": "卯",
    "午": "辰",
    "未": "巳",
    "申": "午",
    "酉": "未",
    "戌": "申",
    "亥": "酉",
    "  ": "  "
  },

  isRefinedDays: ((bz_chart, return_msg) => {
    let result = false;
    let index = [2, 3, 4, 5];

    index.forEach(i => {
      if ((bz_chart.tg[i] === "丙" && bz_chart.eb[i] === "子") || (bz_chart.tg[i] === "戊" && bz_chart.eb[i] === "子")) {
        result = bz_chart.tg[i] + bz_chart.eb[i];
      }
    })

    return result ? result : (return_msg);
  }),

  reference_shooting_star: {
    "甲": "酉",
    "乙": "戌",
    "丙": "未",
    "丁": "申",
    "戊": "巳",
    "己": "午",
    "庚": "辰",
    "辛": "卯",
    "壬": "寅",
    "癸": "亥"
  },

  reference_nobleman_star: {
    "甲": ["丑", "未"],
    "乙": ["子", "申"],
    "丙": ["亥", "酉"],
    "丁": ["亥", "酉"],
    "戊": ["丑", "未"],
    "己": ["子", "申"],
    "庚": ["丑", "未"],
    "辛": ["午", "寅"],
    "壬": ["巳", "卯"],
    "癸": ["巳", "卯"]
  },

  reference_star_of_romance: {
    "子": "卯",
    "丑": "寅",
    "寅": "丑",
    "卯": "子",
    "辰": "亥",
    "巳": "戌",
    "午": "酉",
    "未": "申",
    "申": "未",
    "酉": "午",
    "戌": "巳",
    "亥": "辰",
    "  ": "  "
  },

  reference_star_of_happiness: {
    "子": "酉",
    "丑": "申",
    "寅": "未",
    "卯": "午",
    "辰": "巳",
    "巳": "辰",
    "午": "卯",
    "未": "寅",
    "申": "丑",
    "酉": "子",
    "戌": "亥",
    "亥": "戌",
    "  ": "  "
  },

  reference_heavenly_virtue_star: {
    "子": "巳",
    "丑": "庚",
    "寅": "丁",
    "卯": "申",
    "辰": "壬",
    "巳": "辛",
    "午": "亥",
    "未": "甲",
    "申": "癸",
    "酉": "寅",
    "戌": "丙",
    "亥": "乙",
    "  ": "  "
  },

  reference_monthly_virtue_star: {
    "子": "壬",
    "丑": "庚",
    "寅": "丙",
    "卯": "甲",
    "辰": "壬",
    "巳": "庚",
    "午": "丙",
    "未": "甲",
    "申": "壬",
    "酉": "庚",
    "戌": "丙",
    "亥": "甲",
    "  ": "  "
  },

  isBlessingStar: ((bz_chart, return_msg) => {
    let result = false;
    let tg = bz_chart.tg[4];
    let eb = bz_chart.eb[4];

    if (
      (tg === "甲" && eb === "寅") ||
      (tg === "乙" && eb === "丑") ||
      (tg === "乙" && eb === "亥") ||
      (tg === "丙" && eb === "子") ||
      (tg === "丙" && eb === "戌") ||
      (tg === "丁" && eb === "酉") ||
      (tg === "戊" && eb === "申") ||
      (tg === "己" && eb === "未") ||
      (tg === "庚" && eb === "午") ||
      (tg === "辛" && eb === "巳") ||
      (tg === "壬" && eb === "辰") ||
      (tg === "癸" && eb === "卯")
    ) {
      result = tg + eb;
    }

    return result ? result : (return_msg);
  }),

  reference_five_ghost_star: {
    "子": "辰",
    "丑": "巳",
    "寅": "午",
    "卯": "未",
    "辰": "申",
    "巳": "酉",
    "午": "戌",
    "未": "亥",
    "申": "子",
    "酉": "丑",
    "戌": "寅",
    "亥": "卯",
    "  ": "  "
  },

  reference_misfortune_star: {
    "子": "午",
    "丑": "未",
    "寅": "申",
    "卯": "酉",
    "辰": "戌",
    "巳": "亥",
    "午": "子",
    "未": "丑",
    "申": "寅",
    "酉": "卯",
    "戌": "寅",
    "亥": "巳",
    "  ": "  "
  },

  reference_academic_star: {
    "甲": "巳",
    "乙": "午",
    "丙": "申",
    "丁": "酉",
    "戊": "申",
    "己": "酉",
    "庚": "亥",
    "辛": "子",
    "壬": "寅",
    "癸": "卯"
  },

  reference_sword_star: {
    "甲": ["卯"],
    "乙": ["寅", "辰"],
    "丙": ["午"],
    "丁": ["巳", "未"],
    "戊": ["午"],
    "己": ["巳", "未"],
    "庚": ["酉"],
    "辛": ["申", "戌"],
    "壬": ["子"],
    "癸": ["亥", "丑"]
  },

  reference_prosperity_star: {
    "甲": "寅",
    "乙": "卯",
    "丙": "巳",
    "丁": "午",
    "戊": "巳",
    "己": "午",
    "庚": "申",
    "辛": "酉",
    "壬": "亥",
    "癸": "子"
  },

  reference_golden_carriage_star: {
    "甲": "辰",
    "乙": "巳",
    "丙": "未",
    "丁": "申",
    "戊": "未",
    "己": "申",
    "庚": "戌",
    "辛": "亥",
    "壬": "丑",
    "癸": "寅"
  },

  reference_star_of_general: {
    "子": "子",
    "丑": "酉",
    "寅": "午",
    "卯": "卯",
    "辰": "子",
    "巳": "酉",
    "午": "午",
    "未": "卯",
    "申": "子",
    "酉": "酉",
    "戌": "午",
    "亥": "卯",
    "  ": "  "
  },

  reference_star_of_fine_arts: {
    "子": "辰",
    "丑": "丑",
    "寅": "戌",
    "卯": "未",
    "辰": "辰",
    "巳": "丑",
    "午": "戌",
    "未": "未",
    "申": "辰",
    "酉": "丑",
    "戌": "戌",
    "亥": "未",
    "  ": "  "
  },

  reference_sky_horse: {
    "子": "寅",
    "丑": "亥",
    "寅": "申",
    "卯": "巳",
    "辰": "寅",
    "巳": "亥",
    "午": "申",
    "未": "巳",
    "申": "寅",
    "酉": "亥",
    "戌": "申",
    "亥": "巳",
    "  ": "  "
  },

  reference_robbing_star: {
    "子": "巳",
    "丑": "寅",
    "寅": "亥",
    "卯": "申",
    "辰": "巳",
    "巳": "寅",
    "午": "亥",
    "未": "申",
    "申": "巳",
    "酉": "寅",
    "戌": "亥",
    "亥": "申",
    "  ": "  "
  },

  reference_death_star: {
    "子": "亥",
    "丑": "申",
    "寅": "巳",
    "卯": "寅",
    "辰": "亥",
    "巳": "申",
    "午": "巳",
    "未": "寅",
    "申": "亥",
    "酉": "申",
    "戌": "巳",
    "亥": "寅",
    "  ": "  "
  },

  isAstrociousStar: ((bz_chart, return_msg) => {
    let result = false;
    let index = [2, 3, 4, 5];

    index.forEach(i => {
      if (
        (bz_chart.tg[i] === "甲" && bz_chart.eb[i] === "辰") ||
        (bz_chart.tg[i] === "己" && bz_chart.eb[i] === "丑") ||
        (bz_chart.tg[i] === "乙" && bz_chart.eb[i] === "巳") ||
        (bz_chart.tg[i] === "庚" && bz_chart.eb[i] === "辰") ||
        (bz_chart.tg[i] === "丙" && bz_chart.eb[i] === "申") ||
        (bz_chart.tg[i] === "辛" && bz_chart.eb[i] === "巳") ||
        (bz_chart.tg[i] === "丁" && bz_chart.eb[i] === "亥") ||
        (bz_chart.tg[i] === "壬" && bz_chart.eb[i] === "申") ||
        (bz_chart.tg[i] === "戊" && bz_chart.eb[i] === "戌") ||
        (bz_chart.tg[i] === "癸" && bz_chart.eb[i] === "亥")
      ) {
        result = bz_chart.tg[i] + bz_chart.eb[i];
      }
    })

    return result ? result : (return_msg);
  }),

  isAstrociousClashStar: ((bz_chart, return_msg) => {
    let result = false;
    let tgDay = bz_chart.tg[3];
    let ebDay = bz_chart.eb[3];
    let tgYear = bz_chart.tg[5];
    let ebYear = bz_chart.eb[5];

    if (
      (tgDay === "甲" && ebDay === "辰" && tgYear === "庚" && ebYear === "戌") ||
      (tgDay === "乙" && ebDay === "巳" && tgYear === "辛" && ebYear === "亥") ||
      (tgDay === "丙" && ebDay === "申" && tgYear === "壬" && ebYear === "寅") ||
      (tgDay === "丁" && ebDay === "亥" && tgYear === "癸" && ebYear === "巳") ||
      (tgDay === "戊" && ebDay === "戌" && tgYear === "甲" && ebYear === "辰") ||
      (tgDay === "己" && ebDay === "丑" && tgYear === "乙" && ebYear === "未") ||
      (tgDay === "庚" && ebDay === "辰" && tgYear === "甲" && ebYear === "戌") ||
      (tgDay === "辛" && ebDay === "巳" && tgYear === "乙" && ebYear === "亥") ||
      (tgDay === "壬" && ebDay === "申" && tgYear === "丙" && ebYear === "寅") ||
      (tgDay === "癸" && ebDay === "亥" && tgYear === "丁" && ebYear === "巳")
    ) {
      result = tgDay + ebDay + "  " + tgYear + ebYear;
    }

    return result ? result : (return_msg);
  }),

  reference_solitary_star: {
    "子": "寅",
    "丑": "寅",
    "寅": "巳",
    "卯": "巳",
    "辰": "巳",
    "巳": "申",
    "午": "申",
    "未": "申",
    "申": "亥",
    "酉": "亥",
    "戌": "亥",
    "亥": "寅",
    "  ": "  "
  },

  reference_lonesome_star: {
    "子": "戌",
    "丑": "戌",
    "寅": "丑",
    "卯": "丑",
    "辰": "丑",
    "巳": "辰",
    "午": "辰",
    "未": "辰",
    "申": "未",
    "酉": "未",
    "戌": "未",
    "亥": "戌",
    "  ": "  "
  },

  reference_mourning_star: {
    "子": "寅",
    "丑": "卯",
    "寅": "辰",
    "卯": "丑",
    "辰": "丑",
    "巳": "辰",
    "午": "辰",
    "未": "辰",
    "申": "未",
    "酉": "未",
    "戌": "未",
    "亥": "戌",
    "  ": "  "
  },

  reference_crying_star: {
    "子": "戌",
    "丑": "亥",
    "寅": "子",
    "卯": "丑",
    "辰": "寅",
    "巳": "卯",
    "午": "辰",
    "未": "巳",
    "申": "午",
    "酉": "未",
    "戌": "申",
    "亥": "酉",
    "  ": "  "
  },

  isHeavenlyNobleman: ((bz_chart, return_msg) => {
    let result = false;

    let tgDay = bz_chart.tg[3];
    let tgMonth = bz_chart.tg[4];
    let tgYear = bz_chart.tg[5];

    if (tgDay === "甲" && tgMonth === "戊" && tgYear === "庚") {
      result = tgDay + tgMonth + tgYear;
    }

    return result ? result : (return_msg);
  }),

  isEarthlyNobleman: ((bz_chart, return_msg) => {
    let result = false;

    let tgDay = bz_chart.tg[3];
    let tgMonth = bz_chart.tg[4];
    let tgYear = bz_chart.tg[5];

    if (tgDay === "乙" && tgMonth === "丙" && tgYear === "丁") {
      result = tgDay + tgMonth + tgYear;
    }

    return result ? result : (return_msg);
  }),

  isHumanNobleman: ((bz_chart, return_msg) => {
    let result = false;

    let tgDay = bz_chart.tg[3];
    let tgMonth = bz_chart.tg[4];
    let tgYear = bz_chart.tg[5];

    if (tgDay === "壬" && tgMonth === "癸" && tgYear === "辛") {
      result = tgDay + tgMonth + tgYear;
    }

    return result ? result : (return_msg);
  }),

  isCommanderNobleman: ((bz_chart, return_msg) => {
    let result = false;
    let index = [2, 3, 4, 5];

    index.forEach(i => {

      let tg = bz_chart.tg[i];
      let eb = bz_chart.eb[i];
      if (
        (tg === "壬" && eb === "辰") ||
        (tg === "庚" && eb === "辰") ||
        (tg === "庚" && eb === "戌") ||
        (tg === "戊" && eb === "戌")
      ) {
        result = tg + eb;
      }
    })

    return result ? result : (return_msg);
  }),

  isGoldenSpirit: ((bz_chart, return_msg) => {
    let result = false;

    if (bz_chart.tg[2] === "乙" && bz_chart.eb[2] === "丑") {
      result = "乙丑";
    } else if (bz_chart.tg[2] === "己" && bz_chart.eb[2] === "巳") {
      result = "己巳";
    } else if (bz_chart.tg[2] === "癸" && bz_chart.eb[2] === "酉") {
      result = "癸酉";
    }

    return result ? result : (return_msg);
  }),

  reference_star_of_institution: {
    "甲": "巳",
    "乙": "亥",
    "丙": "丙",
    "丁": "寅",
    "戊": "戊",
    "己": "申",
    "庚": "辛",
    "辛": "巳",
    "壬": "甲",
    "癸": "申"
  },

  is10EnlightenedDays: ((bz_chart, return_msg) => {
    let result = false;

    if (
      (bz_chart.tg[2] === "甲" && bz_chart.eb[2] === "辰") ||
      (bz_chart.tg[3] === "甲" && bz_chart.eb[3] === "辰") ||
      (bz_chart.tg[4] === "甲" && bz_chart.eb[4] === "辰") ||
      (bz_chart.tg[5] === "甲" && bz_chart.eb[5] === "辰")
    ) {
      result = "甲辰";
    }
    else if (
      (bz_chart.tg[2] === "乙" && bz_chart.eb[2] === "亥") ||
      (bz_chart.tg[3] === "乙" && bz_chart.eb[3] === "亥") ||
      (bz_chart.tg[4] === "乙" && bz_chart.eb[4] === "亥") ||
      (bz_chart.tg[5] === "乙" && bz_chart.eb[5] === "亥")
    ) {
      result = "乙亥";
    }
    else if (
      (bz_chart.tg[2] === "丙" && bz_chart.eb[2] === "辰") ||
      (bz_chart.tg[3] === "丙" && bz_chart.eb[3] === "辰") ||
      (bz_chart.tg[4] === "丙" && bz_chart.eb[4] === "辰") ||
      (bz_chart.tg[5] === "丙" && bz_chart.eb[5] === "辰")
    ) {
      result = "丙辰";
    }
    else if (
      (bz_chart.tg[2] === "丁" && bz_chart.eb[2] === "酉") ||
      (bz_chart.tg[3] === "丁" && bz_chart.eb[3] === "酉") ||
      (bz_chart.tg[4] === "丁" && bz_chart.eb[4] === "酉") ||
      (bz_chart.tg[5] === "丁" && bz_chart.eb[5] === "酉")
    ) {
      result = "丁酉";
    }
    else if (
      (bz_chart.tg[2] === "戊" && bz_chart.eb[2] === "午") ||
      (bz_chart.tg[3] === "戊" && bz_chart.eb[3] === "午") ||
      (bz_chart.tg[4] === "戊" && bz_chart.eb[4] === "午") ||
      (bz_chart.tg[5] === "戊" && bz_chart.eb[5] === "午")
    ) {
      result = "戊午";
    }
    else if (
      (bz_chart.tg[2] === "庚" && bz_chart.eb[2] === "戌") ||
      (bz_chart.tg[3] === "庚" && bz_chart.eb[3] === "戌") ||
      (bz_chart.tg[4] === "庚" && bz_chart.eb[4] === "戌") ||
      (bz_chart.tg[5] === "庚" && bz_chart.eb[5] === "戌")
    ) {
      result = "庚戌";
    }
    else if (
      (bz_chart.tg[2] === "庚" && bz_chart.eb[2] === "寅") ||
      (bz_chart.tg[3] === "庚" && bz_chart.eb[3] === "寅") ||
      (bz_chart.tg[4] === "庚" && bz_chart.eb[4] === "寅") ||
      (bz_chart.tg[5] === "庚" && bz_chart.eb[5] === "寅")
    ) {
      result = "庚寅";
    }
    else if (
      (bz_chart.tg[2] === "辛" && bz_chart.eb[2] === "亥") ||
      (bz_chart.tg[3] === "辛" && bz_chart.eb[3] === "亥") ||
      (bz_chart.tg[4] === "辛" && bz_chart.eb[4] === "亥") ||
      (bz_chart.tg[5] === "辛" && bz_chart.eb[5] === "亥")
    ) {
      result = "辛亥";
    }
    else if (
      (bz_chart.tg[2] === "壬" && bz_chart.eb[2] === "寅") ||
      (bz_chart.tg[3] === "壬" && bz_chart.eb[3] === "寅") ||
      (bz_chart.tg[4] === "壬" && bz_chart.eb[4] === "寅") ||
      (bz_chart.tg[5] === "壬" && bz_chart.eb[5] === "寅")
    ) {
      result = "壬寅";
    }
    else if (
      (bz_chart.tg[2] === "癸" && bz_chart.eb[2] === "未") ||
      (bz_chart.tg[3] === "癸" && bz_chart.eb[3] === "未") ||
      (bz_chart.tg[4] === "癸" && bz_chart.eb[4] === "未") ||
      (bz_chart.tg[5] === "癸" && bz_chart.eb[5] === "未")
    ) {
      result = "癸未";
    }

    return result ? result : (return_msg);
  }),

  reference_dragon_virtue: {
    "子": "未",
    "丑": "申",
    "寅": "酉",
    "卯": "戌",
    "辰": "亥",
    "巳": "子",
    "午": "丑",
    "未": "寅",
    "申": "卯",
    "酉": "辰",
    "戌": "巳",
    "亥": "午",
    "  ": "  "
  },

  reference_heavenly_wealth: {
    "甲": "戊",
    "乙": "己",
    "丙": "庚",
    "丁": "辛",
    "戊": "壬",
    "己": "癸",
    "庚": "甲",
    "辛": "乙",
    "壬": "丙",
    "癸": "丁"
  },

  reference_heavenly_doctor_star: {
    "子": "亥",
    "丑": "子",
    "寅": "丑",
    "卯": "寅",
    "辰": "卯",
    "巳": "辰",
    "午": "巳",
    "未": "午",
    "申": "未",
    "酉": "申",
    "戌": "酉",
    "亥": "戌",
    "  ": "  "
  },

  isHeavensGate: ((bz_chart, return_msg) => {
    let result = false;
    let index = [5, 4, 3, 2];

    if (bz_chart.tg[3] === "丙" || bz_chart.tg[3] === "丁" || bz_chart.tg[3] === "壬" || bz_chart.tg[3] === "癸") {
      index.forEach(i => {
        if (bz_chart.eb[i] === "戌") {
          let nextI = i + 1;
          let previousI = i - 1;
          if (nextI !== 6 && bz_chart.eb[nextI] === "亥") {
            result = bz_chart.tg[3] + "+" + bz_chart.eb[i] + bz_chart.eb[nextI];
          } else if (previousI !== 1 && bz_chart.eb[previousI] === "亥") {
            result = bz_chart.tg[3] + "+" + bz_chart.eb[previousI] + bz_chart.eb[i];
          }
        }
      });
    }

    return result ? result : (return_msg);
  }),

  isEarthGate: ((bz_chart, return_msg) => {
    let result = false;
    let index = [5, 4, 3, 2];

    if (bz_chart.tg[3] === "丙" || bz_chart.tg[3] === "丁" || bz_chart.tg[3] === "壬" || bz_chart.tg[3] === "癸") {
      index.forEach(i => {
        if (bz_chart.eb[i] === "辰") {
          let nextI = i + 1;
          let previousI = i - 1;
          if (nextI !== 6 && bz_chart.eb[nextI] === "巳") {
            result = bz_chart.tg[3] + "+" + + bz_chart.eb[i] + bz_chart.eb[nextI];
          } else if (previousI !== 1 && bz_chart.eb[previousI] === "巳") {
            result = bz_chart.tg[3] + "+" + bz_chart.eb[previousI] + bz_chart.eb[i];
          }
        }
      });
    }

    return result ? result : (return_msg);
  }),

  reference_de: {
    "甲子": ["戌", "亥"],
    "乙丑": ["戌", "亥"],
    "丙寅": ["戌", "亥"],
    "丁卯": ["戌", "亥"],
    "戊辰": ["戌", "亥"],
    "己巳": ["戌", "亥"],
    "庚午": ["戌", "亥"],
    "辛未": ["戌", "亥"],
    "壬申": ["戌", "亥"],
    "癸酉": ["戌", "亥"],
    "甲戌": ["申", "酉"],
    "乙亥": ["申", "酉"],
    "丙子": ["申", "酉"],
    "丁丑": ["申", "酉"],
    "戊寅": ["申", "酉"],
    "己卯": ["申", "酉"],
    "庚辰": ["申", "酉"],
    "辛巳": ["申", "酉"],
    "壬午": ["申", "酉"],
    "癸未": ["申", "酉"],
    "甲申": ["午", "未"],
    "乙酉": ["午", "未"],
    "丙戌": ["午", "未"],
    "丁亥": ["午", "未"],
    "戊子": ["午", "未"],
    "己丑": ["午", "未"],
    "庚寅": ["午", "未"],
    "辛卯": ["午", "未"],
    "壬辰": ["午", "未"],
    "癸巳": ["午", "未"],
    "甲午": ["辰", "巳"],
    "乙未": ["辰", "巳"],
    "丙申": ["辰", "巳"],
    "丁酉": ["辰", "巳"],
    "戊戌": ["辰", "巳"],
    "己亥": ["辰", "巳"],
    "庚子": ["辰", "巳"],
    "辛丑": ["辰", "巳"],
    "壬寅": ["辰", "巳"],
    "癸卯": ["辰", "巳"],
    "甲辰": ["寅", "卯"],
    "乙巳": ["寅", "卯"],
    "丙午": ["寅", "卯"],
    "丁未": ["寅", "卯"],
    "戊申": ["寅", "卯"],
    "己酉": ["寅", "卯"],
    "庚戌": ["寅", "卯"],
    "辛亥": ["寅", "卯"],
    "壬子": ["寅", "卯"],
    "癸丑": ["寅", "卯"],
    "甲寅": ["子", "丑"],
    "乙卯": ["子", "丑"],
    "丙辰": ["子", "丑"],
    "丁巳": ["子", "丑"],
    "戊午": ["子", "丑"],
    "己未": ["子", "丑"],
    "庚申": ["子", "丑"],
    "辛酉": ["子", "丑"],
    "壬戌": ["子", "丑"],
    "癸亥": ["子", "丑"]
  },

  taiji_nobleman: {
    "甲": ["子", "午"],
    "乙": ["子", "午"],
    "丙": ["卯", "酉"],
    "丁": ["卯", "酉"],
    "戊": ["丑", "辰", "未", "戌"],
    "己": ["丑", "辰", "未", "戌"],
    "庚": ["寅", "卯"],
    "辛": ["寅", "卯"],
    "壬": ["巳", "申"],
    "癸": ["巳", "申"]
  },

  reference_blood_knife_star: {
    "子": "戌",
    "丑": "酉",
    "寅": "申",
    "卯": "未",
    "辰": "午",
    "巳": "巳",
    "午": "辰",
    "未": "卯",
    "申": "寅",
    "酉": "丑",
    "戌": "子",
    "亥": "亥",
    "  ": "  "
  },

  reference_goat_blade_star: {
    "甲": "卯",
    "乙": "寅",
    "丙": "午",
    "丁": "巳",
    "戊": "午",
    "己": "巳",
    "庚": "酉",
    "辛": "申",
    "壬": "子",
    "癸": "亥"
  },

  reference_mutal_goat_blade: {
    "乙卯": "甲辰",
    "乙酉": "庚辰",
    "丙子": "壬午",
    "丁丑": "癸未",
    "戊子": "壬午",
    "己丑": "癸未",
    "庚辰": "乙酉",
    "辛卯": "甲戌",
    "辛酉": "庚戌",
    "壬午": "丙子",
    "癸未": "丁丑",
  },

  reference_heavenly_dog_star: {
    "子": "戌",
    "丑": "亥",
    "寅": "子",
    "卯": "丑",
    "辰": "寅",
    "巳": "卯",
    "午": "辰",
    "未": "巳",
    "申": "午",
    "酉": "未",
    "戌": "申",
    "亥": "酉",
    "  ": "  "
  },

  reference_death_god_star: {
    "子": "亥",
    "丑": "申",
    "寅": "巳",
    "卯": "寅",
    "辰": "亥",
    "巳": "申",
    "午": "巳",
    "未": "寅",
    "申": "亥",
    "酉": "申",
    "戌": "巳",
    "亥": "寅",
    "  ": "  "
  },

  reference_death_charm_star: {
    "子": "巳",
    "丑": "午",
    "寅": "未",
    "卯": "申",
    "辰": "酉",
    "巳": "戌",
    "午": "亥",
    "未": "子",
    "申": "丑",
    "酉": "寅",
    "戌": "卯",
    "亥": "辰",
    "  ": "  "
  },

  isWildFlowerOfRomance: ((bz_chart, return_msg) => {
    let result = false;
    if (
      data.isPeachBlossom(bz_chart.eb[2]) && data.isPeachBlossom(bz_chart.eb[3]) && data.isPeachBlossom(bz_chart.eb[4]) && data.isPeachBlossom(bz_chart.eb[5])
    ) {
      result = bz_chart.eb[2] + "  " + bz_chart.eb[3] + "  " + bz_chart.eb[4] + "  " + bz_chart.eb[5];
    }

    return result ? result : (return_msg);
  }),

  isNakedFlowerOfRomance: ((bz_chart, return_msg) => {
    let result = false;
    if (
      (
        (bz_chart.tg[3] === "甲") && (bz_chart.eb[3] === "子") && (bz_chart.tg[2] === "庚") && (bz_chart.eb[2] === "午")
      ) ||
      (
        (bz_chart.tg[3] === "庚") && (bz_chart.eb[3] === "午") && (bz_chart.tg[2] === "庚") && (bz_chart.eb[2] === "子")
      ) ||
      (
        (bz_chart.tg[3] === "丁") && (bz_chart.eb[3] === "卯") && (bz_chart.tg[2] === "癸") && (bz_chart.eb[2] === "酉")
      ) ||
      (
        (bz_chart.tg[3] === "癸") && (bz_chart.eb[3] === "酉") && (bz_chart.tg[2] === "丁") && (bz_chart.eb[2] === "卯")
      )
    ) {
      result = bz_chart.tg[3] + bz_chart.eb[3] + "  " + bz_chart.tg[2] + bz_chart.eb[2];
    }

    return result ? result : (return_msg);
  }),

  reference_life_palace_eb: {
    "寅": { "子": "卯", "丑": "寅", "寅": "丑", "卯": "子", "辰": "亥", "巳": "戌", "午": "酉", "未": "申", "申": "未", "酉": "午", "戌": "巳", "亥": "辰" },
    "卯": { "子": "寅", "丑": "丑", "寅": "子", "卯": "亥", "辰": "戌", "巳": "酉", "午": "申", "未": "未", "申": "午", "酉": "巳", "戌": "辰", "亥": "卯" },
    "辰": { "子": "丑", "丑": "子", "寅": "亥", "卯": "戌", "辰": "酉", "巳": "申", "午": "未", "未": "午", "申": "巳", "酉": "辰", "戌": "卯", "亥": "寅" },
    "巳": { "子": "子", "丑": "亥", "寅": "戌", "卯": "酉", "辰": "申", "巳": "未", "午": "午", "未": "巳", "申": "辰", "酉": "卯", "戌": "寅", "亥": "丑" },
    "午": { "子": "亥", "丑": "戌", "寅": "酉", "卯": "申", "辰": "未", "巳": "午", "午": "巳", "未": "辰", "申": "卯", "酉": "寅", "戌": "丑", "亥": "子" },
    "未": { "子": "戌", "丑": "酉", "寅": "申", "卯": "未", "辰": "午", "巳": "巳", "午": "辰", "未": "卯", "申": "寅", "酉": "丑", "戌": "子", "亥": "亥" },
    "申": { "子": "酉", "丑": "申", "寅": "未", "卯": "午", "辰": "巳", "巳": "辰", "午": "卯", "未": "寅", "申": "丑", "酉": "子", "戌": "亥", "亥": "戌" },
    "酉": { "子": "申", "丑": "未", "寅": "午", "卯": "巳", "辰": "辰", "巳": "卯", "午": "寅", "未": "丑", "申": "子", "酉": "亥", "戌": "戌", "亥": "酉" },
    "戌": { "子": "未", "丑": "午", "寅": "巳", "卯": "辰", "辰": "卯", "巳": "寅", "午": "丑", "未": "子", "申": "亥", "酉": "戌", "戌": "酉", "亥": "申" },
    "亥": { "子": "午", "丑": "巳", "寅": "辰", "卯": "卯", "辰": "寅", "巳": "丑", "午": "子", "未": "亥", "申": "戌", "酉": "酉", "戌": "申", "亥": "未" },
    "子": { "子": "巳", "丑": "辰", "寅": "卯", "卯": "寅", "辰": "丑", "巳": "子", "午": "亥", "未": "戌", "申": "酉", "酉": "申", "戌": "未", "亥": "午" },
    "丑": { "子": "辰", "丑": "卯", "寅": "寅", "卯": "丑", "辰": "子", "巳": "亥", "午": "戌", "未": "酉", "申": "申", "酉": "未", "戌": "午", "亥": "巳" }
  },

  reference_life_palace_hs: {
    "甲": { "寅": "丙", "卯": "丁", "辰": "戊", "巳": "己", "午": "庚", "未": "辛", "申": "壬", "酉": "癸", "戌": "甲", "亥": "乙", "子": "丙", "丑": "丁" },
    "乙": { "寅": "戊", "卯": "己", "辰": "庚", "巳": "辛", "午": "壬", "未": "癸", "申": "甲", "酉": "乙", "戌": "丙", "亥": "丁", "子": "戊", "丑": "己" },
    "丙": { "寅": "庚", "卯": "辛", "辰": "壬", "巳": "癸", "午": "甲", "未": "乙", "申": "丙", "酉": "丁", "戌": "戊", "亥": "己", "子": "庚", "丑": "辛" },
    "丁": { "寅": "壬", "卯": "癸", "辰": "甲", "巳": "乙", "午": "丙", "未": "丁", "申": "戊", "酉": "己", "戌": "庚", "亥": "辛", "子": "壬", "丑": "癸" },
    "戊": { "寅": "甲", "卯": "乙", "辰": "丙", "巳": "丁", "午": "戊", "未": "己", "申": "庚", "酉": "辛", "戌": "壬", "亥": "癸", "子": "甲", "丑": "乙" },
    "己": { "寅": "丙", "卯": "丁", "辰": "戊", "巳": "己", "午": "庚", "未": "辛", "申": "壬", "酉": "癸", "戌": "甲", "亥": "乙", "子": "丙", "丑": "丁" },
    "庚": { "寅": "戊", "卯": "己", "辰": "庚", "巳": "辛", "午": "壬", "未": "癸", "申": "甲", "酉": "乙", "戌": "丙", "亥": "丁", "子": "戊", "丑": "己" },
    "辛": { "寅": "庚", "卯": "辛", "辰": "壬", "巳": "癸", "午": "甲", "未": "乙", "申": "丙", "酉": "丁", "戌": "戊", "亥": "己", "子": "庚", "丑": "辛" },
    "壬": { "寅": "壬", "卯": "癸", "辰": "甲", "巳": "乙", "午": "丙", "未": "丁", "申": "戊", "酉": "己", "戌": "庚", "亥": "辛", "子": "壬", "丑": "癸" },
    "癸": { "寅": "甲", "卯": "乙", "辰": "丙", "巳": "丁", "午": "戊", "未": "己", "申": "庚", "酉": "辛", "戌": "壬", "亥": "癸", "子": "甲", "丑": "乙" }
  },

  getLifePalace: ((bz_chart) => {
    let eb1 = data.reference_life_palace_eb[bz_chart.eb[4]];
    let lpeb = eb1[bz_chart.eb[2]];
    let hs1 = data.reference_life_palace_hs[bz_chart.tg[5]];
    let lphs = hs1[lpeb];
    return lphs + lpeb;
  }),

  reference_conception_palace_eb: {
    "子": "卯",
    "丑": "辰",
    "寅": "巳",
    "卯": "午",
    "辰": "未",
    "巳": "申",
    "午": "酉",
    "未": "戌",
    "申": "亥",
    "酉": "子",
    "戌": "丑",
    "亥": "寅"
  },

  reference_conception_palace_hs: {
    "甲": "乙",
    "乙": "丙",
    "丙": "丁",
    "丁": "戊",
    "戊": "己",
    "己": "庚",
    "庚": "辛",
    "辛": "壬",
    "壬": "癸",
    "癸": "甲"
  },

  getConceptionPalace: ((bz_chart) => {
    let cpeb = data.reference_conception_palace_eb[bz_chart.eb[4]];
    let cphs = data.reference_conception_palace_hs[bz_chart.tg[4]];
    return cphs + cpeb;
  }),

  hasElementInChart: ((bz_chart, element, cssClass) => {
    let result = "";
    if (
      bz_chart.tg[5] === element ||
      bz_chart.tg[4] === element ||
      bz_chart.tg[3] === element ||
      bz_chart.tg[2] === element ||
      bz_chart.eb[5] === element ||
      bz_chart.eb[4] === element ||
      bz_chart.eb[3] === element ||
      bz_chart.eb[2] === element
    ) {
      result = cssClass;
    }
    return result;
  }),

  hasPillarInChart: ((bz_chart, pillar, cssClass) => {
    let result = "";
    if (
      (
        bz_chart.tg[5] === pillar[0] &&
        bz_chart.eb[5] === pillar[1]
      ) ||
      (
        bz_chart.tg[4] === pillar[0] &&
        bz_chart.eb[4] === pillar[1]
      ) ||
      (
        bz_chart.tg[3] === pillar[0] &&
        bz_chart.eb[3] === pillar[1]
      ) ||
      (
        bz_chart.tg[2] === pillar[0] &&
        bz_chart.eb[2] === pillar[1]
      )
    ) {
      result = cssClass;
    }
    return result;
  }),

  reference_hs_element: {
    "甲": "木",
    "乙": "木",
    "丙": "火",
    "丁": "火",
    "戊": "土",
    "己": "土",
    "庚": "金",
    "辛": "金",
    "壬": "水",
    "癸": "水",
    "  ": " "
  },

  // not for yi jing
  reference_element_cycle: {
    "木": { "木": "", "火": "po", "土": "co", "金": "ci", "水": "pi" },
    "火": { "木": "pi", "火": "", "土": "po", "金": "co", "水": "ci" },
    "土": { "木": "ci", "火": "pi", "土": "", "金": "po", "水": "co" },
    "金": { "木": "co", "火": "ci", "土": "pi", "金": "", "水": "po" },
    "水": { "木": "po", "火": "co", "土": "ci", "金": "pi", "水": "" }
  },

  reference_branch_6_combi: {
    "子丑": { "type": "combi", "element": "土" }, // CI
    "丑子": { "type": "combi", "element": "土" }, // CO
    "寅亥": { "type": "combi", "element": "木" }, // PI
    "亥寅": { "type": "combi", "element": "木" }, // PO
    "卯戌": { "type": "combi", "element": "火" }, // CO
    "戌卯": { "type": "combi", "element": "火" }, // CI
    "辰酉": { "type": "combi", "element": "金" }, // PO
    "酉辰": { "type": "combi", "element": "金" }, // PI
    "巳申": { "type": "combi", "element": "水" }, // CO
    "申巳": { "type": "combi", "element": "水" }, // CI
    "午未": { "type": "combi", "element": "火" }, // PO
    "未午": { "type": "combi", "element": "火" }, // PI
    "   ": { "type": "", "element": "" }
  },

  reference_branch_3_combi: {
    "申子辰": { "type": "combi", "element": "水" },
    "子申辰": { "type": "combi", "element": "水" },
    "子辰申": { "type": "combi", "element": "水" },
    "申辰子": { "type": "combi", "element": "水" },
    "辰申子": { "type": "combi", "element": "水" },
    "辰子申": { "type": "combi", "element": "水" },
    "亥卯未": { "type": "combi", "element": "木" },
    "卯亥未": { "type": "combi", "element": "木" },
    "卯未亥": { "type": "combi", "element": "木" },
    "亥未卯": { "type": "combi", "element": "木" },
    "未亥卯": { "type": "combi", "element": "木" },
    "未卯亥": { "type": "combi", "element": "木" },
    "寅午戌": { "type": "combi", "element": "火" },
    "午寅戌": { "type": "combi", "element": "火" },
    "午戌寅": { "type": "combi", "element": "火" },
    "寅戌午": { "type": "combi", "element": "火" },
    "戌午寅": { "type": "combi", "element": "火" },
    "戌寅午": { "type": "combi", "element": "火" },
    "巳酉丑": { "type": "combi", "element": "金" },
    "酉巳丑": { "type": "combi", "element": "金" },
    "酉丑巳": { "type": "combi", "element": "金" },
    "巳丑酉": { "type": "combi", "element": "金" },
    "丑巳酉": { "type": "combi", "element": "金" },
    "丑酉巳": { "type": "combi", "element": "金" },
    "  ": { "type": "", "type": "combi", "element": "" }
  },

  reference_branch_6_clash: {
    "子午": { "type": "co" },
    "午子": { "type": "ci" },
    "丑未": { "type": "clash" },
    "未丑": { "type": "clash" },
    "寅申": { "type": "ci" },
    "申寅": { "type": "co" },
    "卯酉": { "type": "ci" },
    "酉卯": { "type": "co" },
    "辰戌": { "type": "clash" },
    "戌辰": { "type": "clash" },
    "巳亥": { "type": "ci" },
    "亥巳": { "type": "co" },
    "   ": { "type": "" }
  },

  reference_branch_self_punishment: {
    "辰辰": { "type": "self_punishment" },
    "午午": { "type": "self_punishment" },
    "酉酉": { "type": "self_punishment" },
    "亥亥": { "type": "self_punishment" },
    "  ": { "type": "" }
  },

  reference_branch_destruction: {
    "子酉": { "type": "destruction" },
    "酉子": { "type": "destruction" },
    "丑辰": { "type": "destruction" },
    "辰丑": { "type": "destruction" },
    "午卯": { "type": "destruction" },
    "卯午": { "type": "destruction" },
    "未戌": { "type": "destruction" },
    "戌未": { "type": "destruction" },
    "申巳": { "type": "destruction" },
    "巳申": { "type": "destruction" },
    "寅亥": { "type": "destruction" },
    "亥寅": { "type": "destruction" },
    "   ": { "type": "" }
  },

  reference_branch_harm: {
    "子未": { "type": "harm" },
    "未子": { "type": "harm" },
    "寅巳": { "type": "harm" },
    "巳寅": { "type": "harm" },
    "申亥": { "type": "harm" },
    "亥申": { "type": "harm" },
    "丑午": { "type": "harm" },
    "午丑": { "type": "harm" },
    "卯辰": { "type": "harm" },
    "辰卯": { "type": "harm" },
    "戌酉": { "type": "harm" },
    "酉戌": { "type": "harm" },
    "   ": { "type": "" }
  },

  reference_interaction_css: {
    "comp": "good",
    "ci": "bad",
    "co": "bad",
    "pi": "good",
    "po": "good",
    "clash": "bad",
    "combi": "good",
    "self_punishment": "bad",
    "destruction": "bad",
    "harm": "bad",
    "": "header"
  },

  reference_conception_life_10_god_meaning: {
    "比肩": {"message":"Develop fortitude. Breed patience, peace, determination, build resilience. Antidote to laziness, is never giving up.", "highlight":"Develop fortitude."},
    "劫财": {"message":"Can be self-centered. Learn to develop concern for other people, learn to be in service, learn to perform acts of generosity, learn to give (do contribution).", "highlight":"(do contribution)."},
    "食神": {"message":"Native must develop compassion. To be compassionate, it is to put other people’s needs before or above yours. It is to be aware of other people’s needs and pains, and to help others alleviate their pains. It is being kind, giving, caring. It is about being thoughtful. This, is the antidote to grief and judgment. Compassion.", "highlight":""},
    "伤官": {"message":"Native is full of self-doubt, must learn to be brave. To be brave is not to have no fear, but to proceed in spite of the fear. May have low self- esteem. Courage.", "highlight":"Courage."},
    "偏财": {"message":"Not that you should own nothing but that nothing should own you. Practice being an observer, rather than being participative emotionally. Stop having “conditions” before you can be happy. Don’t allow external factors to control or shape or influence your emotions. Go from “I need” to “I want”. Go from “I have to” to “I want to”. Accept that you have no control over anything. Detachment is the key.", "highlight":"Detachment is the key."},
    "正财": {"message":"The lesson is to release resentment, vengefulness and bitterness. So that you learn forgiveness.", "highlight":"forgiveness."},
    "七杀": {"message":"May have some addictions (chain smoker, alcoholic etc can have extreme habits). Must exercise some self-control, moderation of needed things and abstinence from things that are not needed. Tend to be overweight. Must learn to live in accordance with a set of higher moral values. Antidote to pride and assertiveness is humility. Temperance – keeping the balance.", "highlight":"Temperance – keeping the balance."},
    "正官": {"message":"Turn the other cheek, forgive and remember to be fair and equitable with others, be more giving (this is the antidote to harboring grievances). It is something we practice, not demand. Be fair.", "highlight":"Be fair."},
    "偏印": {"message":"Develop a more positive view that good always triumphs over evil, that good always wins – this is to counter depression, despair and frustration. Nurture hope.", "highlight":"Nurture hope."},
    "正印": {"message":"Many challenges in life. Lesson is to develop faith (or belief) so that you have more confidence and less doubt. Faith.", "highlight":"Faith."},
  },

  reference_12_phases: {
    "甲": {
      "子": "沐浴",
      "丑": "冠带",
      "寅": "临官",
      "卯": "帝旺",
      "辰": "衰",
      "巳": "病",
      "午": "死",
      "未": "墓",
      "申": "绝",
      "酉": "胎",
      "戌": "养",
      "亥": "长生"
    },
    "乙": {
      "子": "病",
      "丑": "衰",
      "寅": "帝旺",
      "卯": "临官",
      "辰": "冠带",
      "巳": "沐浴",
      "午": "长生",
      "未": "养",
      "申": "胎",
      "酉": "绝",
      "戌": "墓",
      "亥": "死"
    },
    "丙": {
      "子": "胎",
      "丑": "养",
      "寅": "长生",
      "卯": "沐浴",
      "辰": "冠带",
      "巳": "临官",
      "午": "帝旺",
      "未": "衰",
      "申": "病",
      "酉": "死",
      "戌": "墓",
      "亥": "绝"
    },
    "丁": {
      "子": "绝",
      "丑": "墓",
      "寅": "死",
      "卯": "病",
      "辰": "衰",
      "巳": "帝旺",
      "午": "临官",
      "未": "冠带",
      "申": "沐浴",
      "酉": "长生",
      "戌": "养",
      "亥": "胎"
    },
    "戊": {
      "子": "胎",
      "丑": "养",
      "寅": "长生",
      "卯": "沐浴",
      "辰": "冠带",
      "巳": "临官",
      "午": "帝旺",
      "未": "衰",
      "申": "病",
      "酉": "死",
      "戌": "墓",
      "亥": "绝"
    },
    "己": {
      "子": "绝",
      "丑": "墓",
      "寅": "死",
      "卯": "病",
      "辰": "衰",
      "巳": "帝旺",
      "午": "临官",
      "未": "冠带",
      "申": "沐浴",
      "酉": "长生",
      "戌": "养",
      "亥": "胎"
    },
    "庚": {
      "子": "死",
      "丑": "墓",
      "寅": "绝",
      "卯": "胎",
      "辰": "养",
      "巳": "长生",
      "午": "沐浴",
      "未": "冠带",
      "申": "临官",
      "酉": "帝旺",
      "戌": "衰",
      "亥": "病"
    },
    "辛": {
      "子": "长生",
      "丑": "养",
      "寅": "胎",
      "卯": "绝",
      "辰": "墓",
      "巳": "死",
      "午": "病",
      "未": "衰",
      "申": "帝旺",
      "酉": "临官",
      "戌": "冠带",
      "亥": "沐浴"
    },
    "壬": {
      "子": "帝旺",
      "丑": "衰",
      "寅": "病",
      "卯": "死",
      "辰": "墓",
      "巳": "绝",
      "午": "胎",
      "未": "养",
      "申": "长生",
      "酉": "沐浴",
      "戌": "冠带",
      "亥": "临官"
    },
    "癸": {
      "子": "临官",
      "丑": "冠带",
      "寅": "沐浴",
      "卯": "长生",
      "辰": "养",
      "巳": "胎",
      "午": "绝",
      "未": "墓",
      "申": "死",
      "酉": "病",
      "戌": "衰",
      "亥": "帝旺"
    }
  },

  reference_12_phases_luck: {
    "长生":"吉",
    "沐浴":"吉",
    "冠带":"吉",
    "临官":"吉",
    "帝旺":"吉",
    "衰":"凶",
    "病":"凶",
    "死":"凶",
    "墓":"凶",
    "绝":"凶",
    "胎":"平",
    "养":"平"
  },

  reference_12_phases_english: {
    "长生": "Birth",
    "沐浴": "Bath",
    "冠带": "Youth",
    "临官": "Arrival",
    "帝旺": "Peak",
    "衰": "Aging",
    "病": "Sickness",
    "死": "Death",
    "墓": "Burial",
    "绝": "End",
    "胎": "Conceived",
    "养": "Nourish"
  },

  hasBadInteractionInEB:((eb_base, eb_compare) => {
    let result = false;

    // 6 clash
    if(data.reference_branch_6_clash[eb_base + eb_compare])
    {
      result = "6 Clash";
    }

    // self punishment
    if(data.reference_branch_self_punishment[eb_base + eb_compare])
    {
      result = "Self Punishment";
    }

    // destruction
    if(data.reference_branch_destruction[eb_base + eb_compare])
    {
      result = "Destruction";
    }

    // harm
    if(data.reference_branch_harm[eb_base + eb_compare])
    {
      result = "Harm";
    }

    return result;
  })

}



