import React, { Fragment } from 'react';
import { Label } from 'components/lib';

import Autocomplete from "react-google-autocomplete";

export function LocationInput(props) {

  // init default value
  let location = props.value;

  return (
    <Fragment>
      {props.label &&
        <Label text={props.label} />}

      <Autocomplete
        apiKey="AIzaSyBu6pIGnaEJfOUlJfhUj_t5nSZV6k0-nYs"
        style={{ 
          width: "100%", 
          display: "block",
          position: "relative",
          width: "100%",
          color: "#707070",
          fontSize: "1em",
          padding: "1em",
          margin: "0em",
          borderRadius: "2em",
          backgroundColor: "white",
          border: "1px solid #f2f2f2",
        }}
        onPlaceSelected={(place) => {
          // console.log(place);
          location = JSON.stringify(place);
          props.onChange(props.name, place, true);
        }}
      />
      <br/>
      { (!location) && 
        <span>Enter the country and city of where you are born.</span>
      }
      <br/>
      { (location) && 
        <span>Lat: {location?.geometry?.location?.lat()}&nbsp;Long: {location?.geometry?.location?.lng()}</span>
      }
      <br/>
      <br/>
    </Fragment>
  )
}