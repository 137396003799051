import React, { Fragment } from 'react';
import { Label } from 'components/lib';
import TimePicker from 'rc-time-picker';
import ReactDOM from 'react-dom';
import 'rc-time-picker/assets/index.css';
import Style from './input.module.scss';
import { FALSE } from 'node-sass';
import moment from 'moment';

export function TimeInput(props){

  // init default value
  let time = props.value;
  // console.log("Received TIME", time);
  let defaultTime = "";

  if (time && time.indexOf(':') && time.length >= 5){

    time = time.split(':');

    time = moment().set({'hour': time[0], 'minute': time[1]});

  }
  else {

    time = moment();
  }
  // console.log("Processed TIME", time);

  function formatTimeString(d){

    return d.format("HH:mm")

  }

  function change(date){

    // console.log("CHANGE: " , date.format("dddd, MMMM Do YYYY, HH:mm:ss"));
    props.onChange(props.name, formatTimeString(date), true);

    // let valid;

    // if (props.required)
    //   valid = date.year && date.day && date.month ? true : false;

    // props.onChange(props.name, formatDateString(date), valid);

  }

  function customInput({ ref }){

    return (
      <input 
        ref={ ref } 
        value={ props.value?.split('T')[0] }
        placeholder={ props.placeholder || 'Please select a date' }
        className={ Style.textbox }
      />
    );
  }
  
  return (
    <Fragment>

      { props.label && 
        <Label text={ props.label } /> }

        <TimePicker 
          defaultValue={ time }
          onChange={ change }
          showSecond={false}
          className={Style.textbox}
        />

      {/* <DatePicker
        value={ date }
        onChange={ change }
        minimumDate={ props.min }
        maximumDate={ props.max }
        colorPrimary='#73B0F4'
        wrapperClassName={ Style.dateWrapper }
        renderInput={ customInput }
        calendarPopperPosition='bottom'
        inputClassName={ Style.textbox }
      /> */}
    </Fragment>
  )
}