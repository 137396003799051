import React, { Fragment, useState } from 'react';
import { Label } from 'components/lib';
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import DatePicker from 'react-modern-calendar-datepicker';
import { DropdownDate, DropdownComponent } from 'react-dropdown-date';
import Style from './input.module.scss';

// format is YYYY-MM-DD

export function DateInput(props) {

  // init default value
  let date = props.value;

  // console.log("date", date);

  if (date && date.indexOf('-') && date.length >= 10) {
    date = date.split('-');
    date = formatDateString(parseInt(date[0]), parseInt(date[1]), parseInt(date[2]));
  }
  else {

    const now = new Date();
    date = "1980-01-01";
  }

  // console.log("date", date);

  const [state, setState] = useState({ data: null, selectedDate: date });

  function formatDateString(y, m, d) {
    // console.log("YMD", y, m, d);

    return `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`

  }

  function formatDate(date) {	// formats a JS date to 'yyyy-mm-dd'
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  return (
    <Fragment>

      {props.label &&
        <Label text={props.label} />}
      {state.selectedDate &&
        <DropdownDate
          startDate={                       // optional, if not provided 1900-01-01 is startDate
            props.min                    // 'yyyy-mm-dd' format only
          }
          endDate={                         // optional, if not provided current date is endDate
            props.max                    // 'yyyy-mm-dd' format only
          }
          selectedDate={                    // optional
            state.selectedDate         // 'yyyy-mm-dd' format only
          }
          order={[                          // optional // Order of the dropdowns
            DropdownComponent.day,
            DropdownComponent.month,
            DropdownComponent.year
          ]}
          classes={                         // optional
            {
              yearContainer: Style.input_date,
              monthContainer: Style.input_date,
              dayContainer: Style.input_date
            }
          }
          onDateChange={(date) => {         // optional
            // console.log("date", date);
            setState({ selectedDate: formatDate(date) });
            props.onChange(props.name, formatDate(date), true);
            // console.log("state.selectedDate", state.selectedDate);
          }}
        />
      }
    </Fragment>
  )
}