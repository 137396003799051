/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext } from 'react';
import { Button, AuthContext, AppNav, Header, User } from 'components/lib';
import ClassNames from 'classnames';
import Style from './app.module.scss';
import '../layout.scss'; // globals
import { faYinYang } from "@fortawesome/free-solid-svg-icons";


export function AppLayout(props){

  // context & style
  const context = useContext(AuthContext);
  const css = ClassNames([ Style.app, 'with-sidebar' ]);

  return (
    <Fragment>
      <AppNav
        type='popup'
        items={[
          { label: 'Dashboard', icon: 'home', link: '/dashboard' },
          { label: 'Account', icon: 'user', link: '/account', },
          { label: 'Bazi Chart', icon: faYinYang, link: '/personalbazichart', pack: 'fontawesome'},
          { label: 'Help', icon: 'help-circle', link: '/help' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={ css }>

        <Header title={ props.title }>
          <User/>
        </Header>

        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}
