/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Onboarding, Form, Message, Image } from 'components/lib';
import Astronaut from './images/astronaut.jpg';

import Autocomplete from "react-google-autocomplete";

const useAPI = require('components/lib').useAPI;

let finish = false;

export function OnboardingView(props) {

  const context = useContext(AuthContext);
  const views = [{

    name: 'Getting Started',
    description: `Welcome, ${context.user.name}! Please enter the following so that we can generate your charts.`,
    component: <Welcome />

  }]

  if (context.user.duplicate_user) {
    views.unshift({

      name: 'Important!',
      description: '',
      component: <DuplicateUser />

    })
  }

  return <Onboarding save onFinish='/dashboard' views={views} />

}

function DuplicateUser() {

  return (
    <Message
      type='warning'
      title={`You already have an account`}
      text='We noticed you have already registered an account, so we used your original password to save you any confusion in the future.'
    />
  )
}

function Welcome() {
  // fetch
  const authContext = useContext(AuthContext);
  const user = useAPI('/api/user');

  return (
    <div>
      {user?.data &&
        <Form
          buttonText='Save'
          buttonHide={finish}
          url={authContext.user.permission === 'owner' ? '/api/account' : '/api/user'}
          method='PATCH'
          data={{
            name: {
              type: 'hidden',
              default: user.data.name,
              required: true,
            },
            email: {
              type: 'hidden',
              default: user.data.email,
              required: true,
            },
            gender: {
              label: 'Gender',
              type: 'select',
              options: [
                { value: 1, label: 'Male' },
                { value: 0, label: 'Female' }
              ],
              required: true,
            },
            birth_date: {
              label: 'Birth Date',
              type: 'date',
              required: true,
              errorMessage: 'Please enter your date of birth'
            },
            birth_time: {
              label: 'Birth Time',
              type: 'time',
              required: true,
              value: '00:00',
              errorMessage: 'Please enter your time of birth. 24Hr:Min'
            },
            birth_location_full: {
              label: 'Birth Location',
              type: 'location',
              required: true,
              errorMessage: 'Please select the location of birth'
            }
          }}
          callback={res => {
              authContext.update({ name: res.data.data.name })
              finish = true;
            }
          }
        />
      }
    </div>
  )
}