/***
*
*   FEATURES
*   Feature list for use on home/landing pages
*
**********/

import React from 'react';
import { Grid, Icon } from 'components/lib';
import Style from './features.module.scss';

export function Features(props){

  return(
    <div className={ Style.features }>
      <Grid cols={ 3 }>

        <div>
        <Icon image='credit-card' size={ 16 } />
        <h3>Subscription Payments</h3>
        <p>Monetise your SaaS application with a powerful billing system that uses Stripe.</p>
        </div>

        <div>
        <Icon image='droplet' size={ 16 } />
        <h3>React UI</h3>
        <p>Create beautiful user interfaces without any design skills.</p>
        </div>

        <div>
        <Icon image='unlock' size={ 16 } />
        <h3>Users & Authentication</h3>
        <p>Sign up, sign in, reset password – a user authentication system out-of-the-box.</p>
        </div>

        <div>
        <Icon image='settings' size={ 16 } />
        <h3>REST API</h3>
        <p>Blazing fast REST API with token authentication.</p>
        </div>

        <div>
        <Icon image='database' size={ 16 } />
        <h3>Choose Your Database</h3>
        <p>Use MySQL, Postgres, MSSQL, SQLite3 and more with Knex.js</p>
        </div>

        <div>
        <Icon image='users' size={ 16 } />
        <h3>Teams</h3>
        <p>Empower users to work with teammates using a seamless invite system.</p>
        </div>

        <div>
        <Icon image='mail' size={ 16 } />
        <h3>Email Notifications</h3>
        <p>Send beautiful email notifications to your users with Mailgun.</p>
        </div>

        <div>
        <Icon image='bar-chart' size={ 16 } />
        <h3>Master Dashboard</h3>
        <p>Manage your users from your master dashboard - no more database diving.</p>
        </div>

        <div>
        <Icon image='box' size={ 16 } />
        <h3>Pre-built Components</h3>
        <p>From tables and forms to charts and icons, BEING has everything you need.</p>
        </div>

        <div>
        <Icon image='code' size={ 16 } />
        <h3>Unit Tests</h3>
        <p>Hunt down and annihilate bugs in seconds with a full unit testing suite.</p>
        </div>

        <div>
        <Icon image='lock' size={ 16 } />
        <h3>Security & Permissions</h3>
        <p>Manage permissions and restrict access for each user group.</p>
        </div>

        <div>
        <Icon image='refresh-ccw' size={ 16 } />
        <h3>Lifetime Updates</h3>
        <p>Download the full codebase and get access to all new features and updates.</p>
        </div>

      </Grid>
    </div>
  );
}
