/***
*
*   HEADER
*   Header section with title used in main application (can render children)
*
*   PROPS
*   title: title of the view
*   children: children to render (optional)
*
**********/

import React from 'react'
import Style from './header.module.scss';
import { Logo } from 'components/lib';

export function Header(props){

  return (
    <header className={ Style.header }>
      <Logo className={Style.show_print} mark />
      { props.title && <h1>{ props.title }</h1> }
      { props.children }
    </header>
  );
}
