/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React from 'react';
import {
  Card, Stat, ProgressBar, Chart, Table,
  Message, Grid, Animate, Feedback, useAPI
} from 'components/lib';

import { data } from '../../lib/data_reference';

export function Dashboard(props) {

  const stats = useAPI('/api/demo/stats');
  const progress = useAPI('/api/demo/progress');
  const table = useAPI('/api/demo/users/list');
  const userChart = useAPI('/api/demo/users/types');
  const revenueChart = useAPI('/api/demo/revenue');

  // ----
  const user = useAPI('/api/user');

  if (!user.loading) {
    if ((user.data != null) && (user?.data?.dm == null)) {
      window.location = '/welcome';
    }

    if (user.data?.qm_chart && !user.data.qm_chart?.json) {
      user.data.qm_chart = JSON.parse(user?.data?.qm_chart);
      user.data.qm_chart.json = true;
    }
    if (user.data?.bz_chart && !user.data.bz_chart?.json) {
      user.data.bz_chart = JSON.parse(user?.data?.bz_chart);
      user.data.bz_chart.json = true;
    }
    if (user.data?.astro_chart && !user.data.astro_chart?.json) {
      user.data.astro_chart = JSON.parse(user?.data?.astro_chart);
      user.data.astro_chart.json = true;
    }
    // console.log(user.data);
  }
  // ----

  return (
    <Animate type='pop'>

      <Message
        closable
        title='Welcome to BEING!'
        text='A Better Tomorrow, Today.'
        type='info'
      />

      <Grid cols='3'>
        <Stat
          loading={user?.loading}
          value={user?.data?.dm}
          label={data.reference_element_english[user?.data?.dm]}
          emoticon={data.reference_element_icon[user?.data?.dm]}
          title='Day Master'
          action={'/personalbazichart'}
          actiontext='Details'
        />
        <Stat
          loading={user?.loading}
          value={user?.data?.dg}
          label={data.reference_animal_english[user?.data?.dg]}
          emoticon={data.reference_animal_icon[user?.data?.dg]}
          title='Day Earthly Branch'
        />
        <Stat
          loading={user?.loading}
          value={user?.data?.gua + " " + data.reference_life_gua[user?.data?.gua]}
          label={data.reference_life_gua_english[user?.data?.gua]}
          emoticon={data.reference_life_gua_icon[user?.data?.gua]}
          title='Life Gua'
        />
        <Stat
          loading={user?.loading}
          value={user?.data?.qm_chart[user?.data?.dp + "_deity"]}
          label={data.reference_deity_english[user?.data?.qm_chart[user?.data?.dp + "_deity"]]}
          emoticon={data.reference_deity_icon[user?.data?.qm_chart[user?.data?.dp + "_deity"]]}
          title='QiMen Deity'
        />
        <Stat
          loading={user?.loading}
          value={user?.data?.qm_chart[user?.data?.dp + "_star"]}
          label={data.reference_star_english[user?.data?.qm_chart[user?.data?.dp + "_star"]]}
          emoticon={data.reference_star_icon[user?.data?.qm_chart[user?.data?.dp + "_star"]]}
          title='QiMen Star'
        />
        <Stat
          loading={user?.loading}
          value={user?.data?.qm_chart[user?.data?.dp + "_door"]}
          label={data.reference_door_english[user?.data?.qm_chart[user?.data?.dp + "_door"]]}
          emoticon={data.reference_door_icon[user?.data?.qm_chart[user?.data?.dp + "_door"]]}
          title='QiMen Door'
        />
        <Stat
          loading={user?.loading}
          value={data.reference_direction_chinese[user?.data?.dp]}
          label={data.reference_direction_english[user?.data?.dp]}
          emoticon={data.reference_direction_icon[user?.data?.dp]}
          title='QiMen Destiny Palace'
        />
      </Grid>
      <Grid cols='3'>

        <Stat
          loading={user?.loading}
          value={user?.data?.sun_sign}
          label="&nbsp;"
          emoticon={data.reference_astro_sign_icon[user?.data?.sun_sign]}
          title='Sun Sign'
        />
        <Stat
          loading={user?.loading}
          value={user?.data?.moon_sign}
          label="&nbsp;"
          emoticon={data.reference_astro_sign_icon[user?.data?.moon_sign]}
          title='Moon Sign'
        />
        <Stat
          loading={user?.loading}
          value={user?.data?.rising_sign}
          label="&nbsp;"
          emoticon={data.reference_astro_sign_icon[user?.data?.rising_sign]}
          title='Rising Sign'
        />
        <Stat
          loading={user?.loading}
          value={user?.data?.astro_chart.planet_data.TrueNode.sign}
          label="&nbsp;"
          emoticon={data.reference_astro_sign_icon[user?.data?.astro_chart.planet_data.TrueNode.sign]}
          title='North Node Sign'
        />
      </Grid>

    </Animate>
  );
}
