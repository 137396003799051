// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".image_image__35ngB {\n  max-width: 100%;\n}", "",{"version":3,"sources":["webpack://src/components/image/image.module.scss"],"names":[],"mappings":"AAAA;EAEE,eAAA;AAAF","sourcesContent":[".image {\n\n  max-width: 100%;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "image_image__35ngB"
};
export default ___CSS_LOADER_EXPORT___;
