/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, { useState, useContext, useEffect } from 'react';
import { Form, TextInput, DateInput, TimeInput, Select, Button, Card, Animate, Grid, useAPI, Logo } from 'components/lib';

import ClassNames from 'classnames';
import Style from './readingconceptionlife.module.scss';

// -------
import { data } from '../../lib/data_reference';
// import { util } from '../../lib/util';
import moment from 'moment';
// -------
import Axios from 'axios';

async function generateBazi(birthday, time, gender) {

  const res = await Axios({

    method: 'post',
    url: '/api/genbz',
    data: { birthday: birthday, time: time, gender: gender }

  });

  if (res.data) {
    return res.data;
  } else {
    return null;
  }
}

export function ReadingConceptionLifePalace(props) {

  const user = useAPI('/api/user');
  const today_bz = useAPI('/api/bztoday');

  // let bz_chart = null;
  const [bz_chart, setBz_chart] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [birthName, setBirthName] = useState(null);
  const [age, setAge] = useState(0);
  const [current_luck_pillar, setCurrent_luck_pillar] = useState(0);

  let today_bz_chart = null;
  let bday = null;
  let life_palace = null;
  let life_palace_hidden_branch = null;
  let life_palace_clash = null;
  let life_palace_12_phase = null;
  let life_palace_10_god = null;
  let life_palace_10_god_message = null;
  let conception_palace = null;
  let conception_palace_hidden_branch = null;
  let conception_palace_clash = null;
  let conception_palace_12_phase = null;
  let conception_palace_10_god = null;
  let conception_palace_10_god_message = null;

  let solitary_star = [];
  let lonesome_star = [];
  let sky_horse_star = [];
  let peach_blossom = [];
  let nobleman_star = [];
  let death_charm_star = [];
  let robbing_star = [];

  let today = moment();
  // console.log("bz_chart", bz_chart);
  if (!user.loading && user.data && !bz_chart) {
    // console.log("user", user);

    // bz_chart = JSON.parse(user.data.bz_chart);
    let parseBzChart = JSON.parse(user.data.bz_chart);
    setBz_chart(parseBzChart);
    // console.log(bz_chart);

    updateDayPillar(parseBzChart);
  }

  if (!today_bz.loading && today_bz.data && !today_bz_chart) {
    today_bz_chart = today_bz.data;
    // console.log(today_bz_chart);
  }

  let renderTenYearTable = ((start, end) => {
    const content = [];
    for (let dayun_index = start; dayun_index >= end; dayun_index--) {
      content.push(
        <Grid className={Style.tenyeartable_grid_column} cols='1'>
          <span className={
            ClassNames([
              Style.bz_container_header,
              ((age >= bz_chart.dayun_age[dayun_index] && age < bz_chart.dayun_age[dayun_index + 1]) ? Style.current_luck_pillar : "")
            ])
          }>{bz_chart.dayun_age[dayun_index]} ({bz_chart.birthday[0] + bz_chart.dayun_age[dayun_index]})</span>
          <span className={ClassNames(Style.bz_container_tg, ((life_palace_clash == bz_chart.dayun_eb[dayun_index]) ? Style.clash_box_background : ""))}>
            <span className={ClassNames([
              Style.main_element,
              Style[data.reference_main_element_css[bz_chart.dayun_tg[dayun_index]]]])}>{bz_chart.dayun_tg[dayun_index]}</span><span className={Style.main_element_10god}>{data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[dayun_index]]}<span className={Style.main_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_tg_10god[dayun_index]]]}</span></span>
            <span className={Style.main_element_eng}>{data.reference_element_english[bz_chart.dayun_tg[dayun_index]]}</span>
            <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.dayun_tg[dayun_index]]}</span>
          </span>
          <span className={ClassNames(Style.bz_container_eb, ((life_palace_clash == bz_chart.dayun_eb[dayun_index]) ? Style.clash_box_background : ""))}>
            <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[bz_chart.dayun_eb_element[dayun_index]]]])}>{bz_chart.dayun_eb[dayun_index]}</span>
            {
              (bz_chart.kongwang[3] === bz_chart.dayun_eb[dayun_index] || bz_chart.kongwang_element[3] === bz_chart.dayun_eb[dayun_index]) &&
              <span className={Style.main_element_10god}>空<span className={Style.main_element_10god_eng}>DE</span></span>
            }<br />
            <span className={Style.main_element_eng}>{data.reference_animal_ping_ying[bz_chart.dayun_eb[dayun_index]]}</span>
            <span className={Style.main_element_explaination_eng}>{data.reference_animal_english[bz_chart.dayun_eb[dayun_index]]}</span>
            <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.dayun_eb_element[dayun_index]]}</span>
          </span>
          <span className={ClassNames(Style.bz_container_hs, ((life_palace_clash == bz_chart.dayun_eb[dayun_index]) ? Style.clash_box_background : ""))}>
            <span className={Style.hs_part_left}>
              <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.dayun_hs[dayun_index][1]]]])}>{bz_chart.dayun_hs[dayun_index][1]}</span>
              <span className={Style.hs_bottom}>
                <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.dayun_hs[dayun_index][1]]}
                  <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.dayun_hs[dayun_index][1]]}</span></span>
                <span className={Style.hs_element_10god}>
                  {data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][1]]}
                </span>
                <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][1]]]}</span>
              </span>
            </span>
            <span className={Style.hs_part_center}>
              <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.dayun_hs[dayun_index][0]]]])}>{bz_chart.dayun_hs[dayun_index][0]}</span>
              <span className={Style.hs_bottom}>
                <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.dayun_hs[dayun_index][0]]}
                  <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.dayun_hs[dayun_index][0]]}</span></span>
                <span className={ClassNames([Style.hs_element_10god, Style.hs_element_10god_center])}>
                  {data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][0]]}<br />
                </span>
                <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][0]]]}</span>
              </span>
            </span>
            <span className={Style.hs_part_right}>
              <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.dayun_hs[dayun_index][2]]]])}>{bz_chart.dayun_hs[dayun_index][2]}</span>
              <span className={Style.hs_bottom}>
                <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.dayun_hs[dayun_index][2]]}
                  <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.dayun_hs[dayun_index][2]]}</span></span>
                <span className={Style.hs_element_10god}>
                  {data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][2]]}<br />
                </span>
                <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[dayun_index][2]]]}</span>
              </span>
            </span>
          </span>
          {/* <span className={Style.bz_container_eb}>
            <span className={Style.main_element_eng}>
              {bz_chart.dayun_starttime[0][2] + " " + data.reference_month[bz_chart.dayun_starttime[0][1]] + " " + bz_chart.dayun_starttime[0][0] + " " + data.displayTime(bz_chart.dayun_starttime[0][3], bz_chart.dayun_starttime[0][4], bz_chart.dayun_starttime[0][5]) + " " + data.reference_week_day_full[bz_chart.dayun_starttime[0][6]]}
            </span>
          </span> */}
          {/* // use function to print 60 pillar year. set 60 pillar in reference. then create function */}
          {renderAllTenYearTable((bz_chart.birthday[0] + bz_chart.dayun_age[dayun_index]), dayun_index)}
        </Grid>
      );

    }

    return content;
  });

  let renderAllTenYearTable = ((start_year, dayun_index) => {
    const content = [];
    for (let i = 0; i < 10; i++) {
      let current_year = start_year + i;
      let current_60_pillar = data.find_year_60_pillar(current_year);
      content.push(
        <span className={ClassNames(
          Style.bz_container_10year,
          ((life_palace_clash == bz_chart.dayun_eb[dayun_index]) ? Style.clash_box : ""),
          ((life_palace_clash == current_60_pillar[1]) ? Style.clash_box : "")
        )}>
          <span><span className={Style.year_10year}>{current_year}</span>{current_60_pillar}</span>
        </span>
      );
    }

    return content;
  });

  let birth_time = "";
  let readonly = false;

  if (!user.loading && user.data && bz_chart) {
    // for changing mode
    birth_time = bz_chart.birthday[3] + ":" + bz_chart.birthday[4];
    // default_birth_name = user.data.name;
    // console.log("default_birth_name", default_birth_name);

    // chart 10 god
    let current_chart_10_gods = data.reference_bz_10_god[bz_chart.tg[3]];

    // calculate life palace
    life_palace = data.getLifePalace(bz_chart);
    life_palace_hidden_branch = data.reference_hidden_branch[life_palace[1]];
    life_palace_clash = data.reference_animal_clash[life_palace[1]];
    life_palace_12_phase = data.reference_12_phases[bz_chart.tg[3]][life_palace[1]];
    life_palace_10_god = current_chart_10_gods[bz_chart.tg[3]];
    life_palace_10_god_message = data.reference_conception_life_10_god_meaning[life_palace_10_god];
    console.log("life_palace_10_god", life_palace_10_god, life_palace_10_god_message);

    // calculate conception palace
    conception_palace = data.getConceptionPalace(bz_chart);
    conception_palace_hidden_branch = data.reference_hidden_branch[conception_palace[1]];
    conception_palace_clash = data.reference_animal_clash[conception_palace[1]];
    conception_palace_12_phase = data.reference_12_phases[bz_chart.tg[3]][conception_palace[1]];
    conception_palace_10_god = current_chart_10_gods[bz_chart.tg[3]];
    conception_palace_10_god_message = data.reference_conception_life_10_god_meaning[conception_palace_10_god];
    console.log("conception_palace_10_god", conception_palace_10_god, conception_palace_10_god_message);

    // collect solitary star
    solitary_star.push(data.reference_solitary_star[bz_chart.eb[5]]);
    if (bz_chart.eb[5] != bz_chart.eb[3]) {
      solitary_star.push(data.reference_solitary_star[bz_chart.eb[3]]);
    }
    console.log("solitary_star", solitary_star);

    // collect lonesome star
    lonesome_star.push(data.reference_lonesome_star[bz_chart.eb[5]]);
    if (bz_chart.eb[5] != bz_chart.eb[3]) {
      lonesome_star.push(data.reference_lonesome_star[bz_chart.eb[3]]);
    }
    console.log("lonesome_star", lonesome_star);

    // collect sky horse
    sky_horse_star.push(data.reference_sky_horse[bz_chart.eb[5]]);
    if (bz_chart.eb[5] != bz_chart.eb[3]) {
      sky_horse_star.push(data.reference_sky_horse[bz_chart.eb[3]]);
    }
    console.log("sky_horse_star", sky_horse_star);

    // collect peach blossom
    peach_blossom.push(data.reference_peach_blossom[bz_chart.eb[5]]);
    if (bz_chart.eb[5] != bz_chart.eb[3]) {
      peach_blossom.push(data.reference_peach_blossom[bz_chart.eb[3]]);
    }
    console.log("peach_blossom", peach_blossom);

    // collect nobleman
    nobleman_star = data.reference_nobleman_star[bz_chart.tg[3]];
    console.log("nobleman_star", nobleman_star);

    // collect death_charm_star
    death_charm_star.push(data.reference_death_charm_star[bz_chart.eb[5]]);
    if (bz_chart.eb[5] != bz_chart.eb[3]) {
      death_charm_star.push(data.reference_death_charm_star[bz_chart.eb[3]]);
    }
    console.log("death_charm_star", death_charm_star);

    // collect robbing_star
    robbing_star.push(data.reference_robbing_star[bz_chart.eb[5]]);
    if (bz_chart.eb[5] != bz_chart.eb[3]) {
      robbing_star.push(data.reference_robbing_star[bz_chart.eb[3]]);
    }
    console.log("robbing_star", robbing_star);

    // -----
    document.title = "Conception_Life_Reading_" + user.data.name;
  }

  async function updateDayPillar(parseBzChart) {
    // console.log("age", age);
    bday = moment(parseBzChart.birthday);
    let cal_age = today.diff(bday, 'years');
    // console.log("age", cal_age);
    for (let dayun_index = 9; dayun_index >= 0; dayun_index--) {
      if (cal_age >= parseBzChart.dayun_age[dayun_index] && cal_age < parseBzChart.dayun_age[dayun_index + 1]) {
        setCurrent_luck_pillar(dayun_index);
        // console.log(current_luck_pillar);
      }
    }
    user.data.age = cal_age;
    setAge(cal_age);
  }

  async function update(a, b, c) {
    let bd = birthDate;
    let bt = birth_time;
    let g = user.data.gender;

    switch (a) {
      case "birthdate":
        bd = b;
        setBirthDate(bd);
        break;

      case "birthtime":
        bt = b;
        birth_time = b;
        break;

      case "gender":
        g = b;
        user.data.gender = b;
        break;

      default:
        break;
    }

    let bz = await recalculateChart(bd, bt, g);
    user.data.dm = bz.tg[3];
    user.data.gua = bz.gua;
    setBz_chart(bz);
    updateDayPillar(bz);
  }

  function updateName(a, b, c, d) {
    setBirthName(b);
    user.data.name = b;
  }

  async function recalculateChart(bd, bt, g) {
    // util
    // console.log("bd, bt, g", bd, bt, g);
    let result = await generateBazi(bd, bt, g);
    return result.data;
  }

  useEffect(() => {
    // Update the document title using the browser API
    if (!user.loading && user?.data && birthName == null) {
      setBirthName(user.data.name);
    }
    if (bz_chart && birthDate == null) {

      // console.log("bz_chart.birthday", bz_chart.birthday);
      setBirthDate(
        bz_chart.birthday[0]
        + "-" +
        ("0" + bz_chart.birthday[1]).slice(-2)
        + "-" +
        ("0" + bz_chart.birthday[2]).slice(-2));
    }
  });

  return (
    <Animate type='pop'>
      {((!user.loading) && bz_chart && today_bz_chart) &&
        <Grid cols='1'>
          {user.data.internal_team == 1 &&
            <Card title={'Edit Mode (MASTER MODE)'} className={[Style.show_overflow_visible, Style.hidePrint]}>
              <Grid className={[Style.basic_info_table_grid, Style.show_overflow_visible, Style.height_higher]} cols='5'>
                <Grid className={[Style.basic_info_table_grid_column, Style.show_overflow_visible, Style.height_higher]} cols='1'>
                  <span className={Style.bz_container_header_bigger}>Birth Date and Time<br />出生时辰</span>
                </Grid>
                <Grid className={[Style.basic_info_table_grid_column, Style.show_overflow_visible, Style.long_input, Style.height_higher]} cols='1'>
                  <DateInput
                    name="birthdate"
                    value={birthDate}
                    onChange={update}
                    required={readonly}
                    readonly={readonly}
                    max="2050-12-31"
                  />
                </Grid>
                <Grid className={[Style.basic_info_table_grid_column, Style.show_overflow_visible, Style.short_input, Style.height_higher]} cols='1'>
                  <TimeInput name="birthtime" value={birth_time} onChange={update} />
                </Grid>
                <Grid className={[Style.basic_info_table_grid_column, Style.height_higher]} cols='1'>
                  <span className={Style.bz_container_header_bigger}>Gender<br />性别</span>
                </Grid>
                <Grid className={[Style.basic_info_table_grid_column, Style.height_higher]} cols='1'>
                  <Select name="gender" value={{ value: 1, label: 'Male' }} default={user.data.gender} onChange={update}
                    options={[
                      { value: 1, label: 'Male' },
                      { value: 0, label: 'Female' }
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid className={[Style.basic_info_table_grid, Style.show_overflow_visible]} cols='5'>
                <Grid className={[Style.basic_info_table_grid_column, Style.height_higher]} cols='1'>
                  <span className={Style.bz_container_header_bigger}>Name<br />名字</span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <TextInput
                    key="key"
                    type="text"
                    form="noone"
                    className="Nolabel"
                    name="Name"
                    default="def"
                    value={birthName}
                    required={readonly}
                    readonly={readonly}
                    text="text"
                    title="title"
                    errorMessage="ErrorMsg"
                    onChange={updateName}
                  />
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <div> </div>
                </Grid>
              </Grid>
            </Card>
          }
          <Card title='' loading={user.loading}>
            <Card title={'Basic Information 基本讯息'} className={Style.remove_margin}>
              <Grid className={Style.basic_info_table_grid} cols='6'>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Name<br />名字</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span>{user.data.name}</span>
                </Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Birth Date and Time<br />出生时辰</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>{bz_chart.birthday[2] + " " + data.reference_month[bz_chart.birthday[1]] + " " + bz_chart.birthday[0]}<br />{data.displayTime(bz_chart.birthday[3], bz_chart.birthday[4], bz_chart.birthday[5]) + " " + data.reference_week_day_full[bz_chart.birthday[6]]}</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'><span className={Style.bz_container_header}>Gender<br />性别</span></Grid>
                <Grid className={Style.basic_info_table_grid_column} cols='1'>
                  <span className={Style.main_element_explaination_eng}>{data.reference_gender_chinese[user.data.gender]}<br />{data.reference_gender[user.data.gender]}</span></Grid>
              </Grid>
            </Card>

            <Card title='Bazi Chart' className={Style.remove_margin}>
              <Grid className={Style.bztable_grid} cols='6'>
                <Grid className={Style.bztable_grid_column} cols='1'>
                  <span className={Style.bz_container_header}>HOUR<br />时</span>
                  <span className={Style.bz_container_tg}>
                    <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[bz_chart.tg[2]]]])}>{bz_chart.tg[2]}</span><span className={Style.main_element_10god}>{data.reference_10_god_from_lunar[bz_chart.tg_10god[2]]}<span className={Style.main_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.tg_10god[2]]]}</span></span>
                    <span className={Style.main_element_eng}>{data.reference_element_english[bz_chart.tg[2]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.tg[2]]}</span>
                  </span>
                  <span className={Style.bz_container_eb}>
                    <span className={ClassNames(Style.main_element, Style[data.reference_main_element_css[bz_chart.eb_element[2]]], ((life_palace_clash == bz_chart.eb[2]) ? Style.clash_box : ""))}>{bz_chart.eb[2]}</span>
                    {
                      (bz_chart.kongwang[3] === bz_chart.eb[2] || bz_chart.kongwang_element[3] === bz_chart.eb[2]) &&
                      <span className={Style.main_element_10god}>空<span className={Style.main_element_10god_eng}>DE</span></span>
                    }<br />
                    <span className={Style.main_element_eng}>{data.reference_animal_ping_ying[bz_chart.eb[2]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_animal_english[bz_chart.eb[2]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.eb_element[2]]}</span><br />
                  </span>
                  <span className={Style.bz_container_hs}>
                    <span className={Style.hs_part_left}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[2][1]]]])}>{bz_chart.hs[2][1]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[2][1]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[2][1]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[2][1]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[2][1]]]}</span>
                        </span>
                      </span>
                    </span>
                    <span className={Style.hs_part_center}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[2][0]]]])}>{bz_chart.hs[2][0]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[2][0]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[2][0]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[2][0]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[2][0]]]}</span>
                        </span>
                      </span>
                    </span>
                    <span className={Style.hs_part_right}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[2][2]]]])}>{bz_chart.hs[2][2]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[2][2]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[2][2]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[2][2]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[2][2]]]}</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </Grid>
                <Grid className={Style.bztable_grid_column} cols='1'>
                  <span className={Style.bz_container_header}>DAY<br />日</span>
                  <span className={Style.bz_container_tg}>
                    <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[bz_chart.tg[3]]]])}>{bz_chart.tg[3]}</span>
                    <span className={Style.main_element_eng}>{data.reference_element_english[bz_chart.tg[3]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.tg[3]]}</span>
                  </span>
                  <span className={Style.bz_container_eb}>
                    <span className={ClassNames(Style.main_element, Style[data.reference_main_element_css[bz_chart.eb_element[3]]], ((life_palace_clash == bz_chart.eb[3]) ? Style.clash_box : ""))}>{bz_chart.eb[3]}</span>
                    {
                      (bz_chart.kongwang[3] === bz_chart.eb[3] || bz_chart.kongwang_element[3] === bz_chart.eb[3]) &&
                      <span className={Style.main_element_10god}>空<span className={Style.main_element_10god_eng}>DE</span></span>
                    }<br />
                    <span className={Style.main_element_eng}>{data.reference_animal_ping_ying[bz_chart.eb[3]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_animal_english[bz_chart.eb[3]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.eb_element[3]]}</span><br />
                  </span>
                  <span className={Style.bz_container_hs}>
                    <span className={Style.hs_part_left}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[3][1]]]])}>{bz_chart.hs[3][1]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[3][1]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[3][1]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[3][1]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[3][1]]]}</span>
                        </span>
                      </span>
                    </span>
                    <span className={Style.hs_part_center}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[3][0]]]])}>{bz_chart.hs[3][0]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[3][0]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[3][0]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[3][0]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[3][0]]]}</span>
                        </span>
                      </span>
                    </span>
                    <span className={Style.hs_part_right}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[3][2]]]])}>{bz_chart.hs[3][2]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[3][2]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[3][2]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[3][2]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[3][2]]]}</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </Grid>
                <Grid className={Style.bztable_grid_column} cols='1'>
                  <span className={Style.bz_container_header}>MONTH<br />月</span>
                  <span className={Style.bz_container_tg}>
                    <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[bz_chart.tg[4]]]])}>{bz_chart.tg[4]}</span><span className={Style.main_element_10god}>{data.reference_10_god_from_lunar[bz_chart.tg_10god[4]]}<span className={Style.main_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.tg_10god[4]]]}</span></span>
                    <span className={Style.main_element_eng}>{data.reference_element_english[bz_chart.tg[4]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.tg[4]]}</span>
                  </span>
                  <span className={Style.bz_container_eb}>
                    <span className={ClassNames(Style.main_element, Style[data.reference_main_element_css[bz_chart.eb_element[4]]], ((life_palace_clash == bz_chart.eb[4]) ? Style.clash_box : ""))}>{bz_chart.eb[4]}</span>
                    {
                      (bz_chart.kongwang[3] === bz_chart.eb[4] || bz_chart.kongwang_element[3] === bz_chart.eb[4]) &&
                      <span className={Style.main_element_10god}>空<span className={Style.main_element_10god_eng}>DE</span></span>
                    }<br />
                    <span className={Style.main_element_eng}>{data.reference_animal_ping_ying[bz_chart.eb[4]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_animal_english[bz_chart.eb[4]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.eb_element[4]]}</span><br />
                  </span>
                  <span className={Style.bz_container_hs}>
                    <span className={Style.hs_part_left}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[4][1]]]])}>{bz_chart.hs[4][1]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[4][1]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[4][1]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[4][1]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[4][1]]]}</span>
                        </span>
                      </span>
                    </span>
                    <span className={Style.hs_part_center}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[4][0]]]])}>{bz_chart.hs[4][0]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[4][0]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[4][0]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[4][0]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[4][0]]]}</span>
                        </span>
                      </span>
                    </span>
                    <span className={Style.hs_part_right}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[4][2]]]])}>{bz_chart.hs[4][2]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[4][2]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[4][2]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[4][2]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[4][2]]]}</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </Grid>
                <Grid className={Style.bztable_grid_column} cols='1'>
                  <span className={Style.bz_container_header}>YEAR<br />年</span>
                  <span className={Style.bz_container_tg}>
                    <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[bz_chart.tg[5]]]])}>{bz_chart.tg[5]}</span><span className={Style.main_element_10god}>{data.reference_10_god_from_lunar[bz_chart.tg_10god[5]]}<span className={Style.main_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.tg_10god[5]]]}</span></span>
                    <span className={Style.main_element_eng}>{data.reference_element_english[bz_chart.tg[5]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.tg[5]]}</span>
                  </span>
                  <span className={Style.bz_container_eb}>
                    <span className={ClassNames(Style.main_element, Style[data.reference_main_element_css[bz_chart.eb_element[5]]], ((life_palace_clash == bz_chart.eb[5]) ? Style.clash_box : ""))}>{bz_chart.eb[5]}</span>
                    {
                      (bz_chart.kongwang[3] === bz_chart.eb[5] || bz_chart.kongwang_element[3] === bz_chart.eb[5]) &&
                      <span className={Style.main_element_10god}>空<span className={Style.main_element_10god_eng}>DE</span></span>
                    }<br />
                    <span className={Style.main_element_eng}>{data.reference_animal_ping_ying[bz_chart.eb[5]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_animal_english[bz_chart.eb[5]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.eb_element[5]]}</span><br />
                  </span>
                  <span className={Style.bz_container_hs}>
                    <span className={Style.hs_part_left}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[5][1]]]])}>{bz_chart.hs[5][1]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[5][1]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[5][1]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[5][1]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[5][1]]]}</span>
                        </span>
                      </span>
                    </span>
                    <span className={Style.hs_part_center}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[5][0]]]])}>{bz_chart.hs[5][0]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[5][0]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[5][0]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[5][0]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[5][0]]]}</span>
                        </span>
                      </span>
                    </span>
                    <span className={Style.hs_part_right}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[bz_chart.hs[5][2]]]])}>{bz_chart.hs[5][2]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[bz_chart.hs[5][2]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[bz_chart.hs[5][2]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.hs_element[5][2]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.hs_element[5][2]]]}</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </Grid>
                <Grid className={Style.bztable_grid_column} cols='1'>
                  <span className={Style.bz_container_header_sub}>CONCEPTION PALACE<br />胎元</span>
                  <span className={Style.bz_container_tg}>
                    <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[conception_palace[0]]]])}>{conception_palace[0]}</span><span className={Style.main_element_10god}>{data.reference_bz_10_god[bz_chart.tg[3]][conception_palace[0]]}<span className={Style.main_element_10god_eng}>{data.reference_10_god_english[data.reference_bz_10_god[bz_chart.tg[3]][conception_palace[0]]]}</span></span>
                    <span className={Style.main_element_eng}>{data.reference_element_english[conception_palace[0]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[conception_palace[0]]}</span>
                  </span>
                  <span className={Style.bz_container_eb}>
                    <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[data.reference_earthly_branch_element[conception_palace[1]]]]])}>{conception_palace[1]}</span>
                    {
                      (today_bz_chart.kongwang[3] === conception_palace[1] || today_bz_chart.kongwang_element[3] === conception_palace[1]) &&
                      <span className={Style.main_element_10god}>空<span className={Style.main_element_10god_eng}>DE</span></span>
                    }<br />
                    <span className={Style.main_element_eng}>{data.reference_animal_ping_ying[conception_palace[1]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_animal_english[conception_palace[1]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[data.reference_earthly_branch_element[conception_palace[1]]]}</span><br />
                  </span>
                  <span className={Style.bz_container_hs}>
                    <span className={Style.hs_part_left}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[conception_palace_hidden_branch[1]]]])}>{conception_palace_hidden_branch[1]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[conception_palace_hidden_branch[1]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[conception_palace_hidden_branch[1]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[conception_palace_hidden_branch[1]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[conception_palace_hidden_branch[1]]]}</span>
                        </span>
                      </span>
                    </span>
                    <span className={Style.hs_part_center}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[conception_palace_hidden_branch[0]]]])}>{conception_palace_hidden_branch[0]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[conception_palace_hidden_branch[0]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[conception_palace_hidden_branch[0]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[today_bz_chart.hs_element[5][0]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[today_bz_chart.hs_element[5][0]]]}</span>
                        </span>
                      </span>
                    </span>
                    <span className={Style.hs_part_right}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[conception_palace_hidden_branch[2]]]])}>{conception_palace_hidden_branch[2]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[conception_palace_hidden_branch[2]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[conception_palace_hidden_branch[2]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[today_bz_chart.hs_element[5][2]]}
                          <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[today_bz_chart.hs_element[5][2]]]}</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </Grid>
                <Grid className={Style.bztable_grid_column} cols='1'>
                  <span className={Style.bz_container_header_sub}>LIFE PALACE<br />命宫</span>
                  <span className={Style.bz_container_tg}>
                    <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[life_palace[0]]]])}>{life_palace[0]}</span><span className={Style.main_element_10god}>{data.reference_bz_10_god[bz_chart.tg[3]][life_palace[0]]}<span className={Style.main_element_10god_eng}>{data.reference_10_god_english[data.reference_bz_10_god[bz_chart.tg[3]][life_palace[0]]]}</span></span>
                    <span className={Style.main_element_eng}>{data.reference_element_english[life_palace[0]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[life_palace[0]]}</span>
                  </span>
                  <span className={Style.bz_container_eb}>
                    <span className={ClassNames([Style.main_element, Style[data.reference_main_element_css[data.reference_earthly_branch_element[life_palace[1]]]]])}>{life_palace[1]}</span>
                    {
                      (bz_chart.kongwang[3] === life_palace[1] || bz_chart.kongwang_element[3] === life_palace[1]) &&
                      <span className={Style.main_element_10god}>空<span className={Style.main_element_10god_eng}>DE</span></span>
                    }<br />
                    <span className={Style.main_element_eng}>{data.reference_animal_ping_ying[life_palace[1]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_animal_english[life_palace[1]]}</span>
                    <span className={Style.main_element_explaination_eng}>{data.reference_element_explaination_english[data.reference_earthly_branch_element[life_palace[1]]]}</span><br />
                  </span>
                  <span className={Style.bz_container_hs}>
                    <span className={Style.hs_part_left}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[life_palace_hidden_branch[1]]]])}>{life_palace_hidden_branch[1]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[life_palace_hidden_branch[1]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[life_palace_hidden_branch[1]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[current_luck_pillar][1]]}
                        </span>
                        <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[current_luck_pillar][1]]]}</span>
                      </span>
                    </span>
                    <span className={Style.hs_part_center}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[life_palace_hidden_branch[0]]]])}>{life_palace_hidden_branch[0]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[life_palace_hidden_branch[0]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[life_palace_hidden_branch[0]]}</span></span>
                        <span className={ClassNames([Style.hs_element_10god, Style.hs_element_10god_center])}>
                          {data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[current_luck_pillar][0]]}<br />
                        </span>
                        <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[current_luck_pillar][0]]]}</span>
                      </span>
                    </span>
                    <span className={Style.hs_part_right}>
                      <span className={ClassNames([Style.hs_element, Style[data.reference_main_element_css[life_palace_hidden_branch[2]]]])}>{life_palace_hidden_branch[2]}</span>
                      <span className={Style.hs_bottom}>
                        <span className={Style.hs_element_eng}>{data.reference_element_english[life_palace_hidden_branch[2]]}
                          <span className={Style.hs_element_explaination_eng}>{data.reference_element_explaination_english[life_palace_hidden_branch[2]]}</span></span>
                        <span className={Style.hs_element_10god}>
                          {data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[current_luck_pillar][2]]}<br />
                        </span>
                        <span className={Style.hs_element_10god_eng}>{data.reference_10_god_english[data.reference_10_god_from_lunar[bz_chart.dayun_hs_element[current_luck_pillar][2]]]}</span>
                      </span>
                    </span>
                  </span>
                </Grid>
              </Grid>
            </Card>

            <Card title='10 Year Luck Pillar' className={Style.remove_margin}>
              <Grid className={Style.tenyeartable_grid} cols='1'>
                <Grid className={Style.tenyeartable_grid_container} cols='10'>
                  {renderTenYearTable(9, 0)}
                </Grid>
                <span>Highlighted boxes are the clashed year and luck pillar.</span>
              </Grid>
            </Card>

            <div className={Style.page_break}></div>
            <div className={[Style.header_logo, Style.show_print]}>
              <Logo className={Style.show_print} mark />
            </div>

            <Card title='House of Life (Life Palace) Interpretations'>
              <ul className={Style.interpretation}>
                <li>
                  In your Life Palace branch, sits {life_palace[1]}. When you see {life_palace_clash} in the 10 year luck pillars or annual pillars, it forms a clash - beware of accidents or injuries.
                  {
                    // Life Palace Clash same as DE
                    (bz_chart.kongwang[3] === life_palace_clash || bz_chart.kongwang_element[3] === life_palace_clash) &&
                    <span> But note that {life_palace_clash} is your DE star.</span>
                  }
                </li>
                {
                  // Life Palace Clash Year or Day
                  ((data.hasBadInteractionInEB(bz_chart.eb[5], life_palace[1])) || data.hasBadInteractionInEB(bz_chart.eb[3], life_palace[1])) &&
                  <li>
                    House of Life's Earthly Branch
                    {
                      (data.hasBadInteractionInEB(bz_chart.eb[5], life_palace[1])) &&
                      <span> forms a "{data.hasBadInteractionInEB(bz_chart.eb[5], life_palace[1])}" with the Year Pillar {bz_chart.eb[5]}</span>
                    }
                    {
                      (data.hasBadInteractionInEB(bz_chart.eb[3], life_palace[1])) &&
                      <span>
                        {
                          (data.hasBadInteractionInEB(bz_chart.eb[5], life_palace[1])) &&
                          <span> and</span>
                        }
                        &nbsp;forms a "{data.hasBadInteractionInEB(bz_chart.eb[3], life_palace[1])}" with the Day Pillar {bz_chart.eb[3]}</span>
                    }
                    .<br />
                    Time to develop and live by the "wealth mentality"
                    (you are subconsciously operating from a "poor man's" mentality.<br />
                    !!!What to show? life force is weak (possibly short life) / poverty.!!!
                  </li>
                }
                {
                  // Life Palace same as Death Charm Star or Robbing Sha Star
                  ((death_charm_star.find(element => element === life_palace[1])) || (robbing_star.find(element => element === life_palace[1]))) &&
                  <li>
                    House of Life star happens to be your
                    {
                      (death_charm_star.find(element => element === life_palace[1])) &&
                      <span>Death Charm Star</span>
                    }
                    {
                      (robbing_star.find(element => element === life_palace[1])) &&
                      <span>
                        {
                          (death_charm_star.find(element => element === life_palace[1])) &&
                          <span> and </span>
                        }
                        &nbsp;Robbing Sha Star
                      </span>
                    }
                    &nbsp;as well. The native can be sickly.
                    {
                      // Life Palace same as Death Charm Star or Robbing Sha Star and Is Female
                      (user.data.gender == 0) &&
                      <span> May have difficulty in child birth.</span>
                    }
                  </li>
                }
                {
                  // Life Palace same as Solitary Star
                  (solitary_star.find(element => element === life_palace[1])) &&
                  <li>
                    House of Life star happens to be your Solitary Star as well - it implies difficulties in marriage, or loneliness - an inability to connect emotionally or be "on the same page" or "of the same thought".
                    {
                      // Life Palace same as Solitary Star and Is Female
                      (user.data.gender == 0) &&
                      <span> Native may become nun.</span>
                    }
                  </li>
                }
                {
                  // Life Palace same as Sky Horse Star
                  (sky_horse_star.find(element => element === life_palace[1])) &&
                  <li>House of Life star happens to be your Sky Horse Star as well - it implies migration.</li>
                }
                {
                  // Life Palace same as Sky Horse Star and 12 phase is "Coming of Age"
                  ((life_palace_12_phase == "临官") && (sky_horse_star.find(element => element === life_palace[1]))) &&
                  <li>
                    As the 12 phase is "Coming of Age", the native is of flirtatious nature.
                    {
                      // Female and Life Palace same as Sky Horse Star and 12 phase is "Coming of Age"
                      (user.data.gender == 0) &&
                      <span> Native may be mistress or perhaps "common law wife" status only (not legal wife).</span>
                    }
                  </li>
                }
                {
                  // Life Palace same as Peach Blossom Star
                  (peach_blossom.find(element => element === life_palace[1])) &&
                  <li>
                    House of Life star happens to be your Peach Blossom Star as well - Native is romantic and flirtatious nature.
                    {
                      // if DE
                      (bz_chart.kongwang[3] === life_palace[1] || bz_chart.kongwang_element[3] === life_palace[1]) &&
                      <span> When DE appears, it mellows this negative trait</span>
                    }
                  </li>
                }
                {
                  // Life Palace same as Peach Blossom and Sky Horse
                  ((death_charm_star.find(element => element === life_palace[1])) && (robbing_star.find(element => element === life_palace[1]))) &&
                  <li>As House of Life star is same as Peach Blossom and Sky Horse, Affairs of the heart promises to be problematic.
                    {
                      // if Female
                      (user.data.gender == 0) &&
                      <span> If female, may miscarry.</span>
                    }
                  </li>
                }
                {
                  // Wealth Star Appears on House of Life
                  ((life_palace_10_god === "正财") || (life_palace_10_god === "偏财")) &&
                  <li>As Wealth Star appears on House of Life Star, the native is Wealthy.</li>
                }
                {
                  // Life Palace Pillar same as Month Branch
                  (bz_chart.tg[4] + bz_chart.eb[4] === life_palace) &&
                  <li>As Life Palace Pillar same as Month Branch pillar, Native is knowledgeable and at his (or her) core, is a decision maker (decisive).</li>
                }
                {
                  // Life Palace same as nobleman Star
                  (nobleman_star.find(element => element === life_palace[1])) &&
                  <li>
                    House of Life with “Nobleman” star indicates there are many helpful people around.
                    {
                      // House of Life with "Nobleman" at stage of "Birth" or "Coming of Age"
                      ((life_palace_12_phase == "长生") || (life_palace_12_phase == "临官")) &&
                      <span>House of Life with “Nobleman” at stage of “Birth” or “Coming of Age” indicates a learned and intelligent person.</span>
                    }
                  </li>
                }
              </ul>
            </Card>
            <Card title='House of Life (Life Palace), House of Conception (Conception Palace) and 10 God Interpretations'>
              <Grid className={Style.bztable_grid} cols='2'>
                <Grid className={ClassNames([Style.bztable_grid_column, Style.meaning_10god_pillar])} cols='1'>
                  <span className={ClassNames([Style.bz_container_header])}>10 God<br />十神</span>
                  <span className={Style.bz_container_tg}>
                    <span className={ClassNames([Style.main_element])}>{life_palace_10_god}</span><br />
                    <span className={Style.main_element_eng}>{data.reference_10_god_english[life_palace_10_god]}</span><br />
                  </span>
                  {
                    // if life palace is different from conception palace
                    (life_palace != conception_palace) &&
                    <span className={Style.bz_container_tg}>
                      <span className={ClassNames([Style.main_element])}>{conception_palace}</span><br />
                      <span className={Style.main_element_eng}>{data.reference_10_god_english[conception_palace_10_god]}</span><br />
                    </span>
                  }
                </Grid>
                <Grid className={ClassNames([Style.bztable_grid_column, Style.meaning_meaning_pillar])} cols='1'>
                  <span className={ClassNames([Style.bz_container_header])}>Meaning<br />解读</span>
                  <span className={Style.bz_container_tg}>
                    <span className={Style.main_element_eng}>{life_palace_10_god_message.message.replace(life_palace_10_god_message.highlight, '')} <span className={Style.highlight_10god}>{life_palace_10_god_message.highlight}</span></span><br />
                  </span>
                  {
                    // if life palace is different from conception palace
                    (life_palace != conception_palace) &&
                    <span className={Style.bz_container_tg}>
                      <span className={Style.main_element_eng}>{life_palace_10_god_message.message.replace(conception_palace_10_god_message.highlight, '')} <span className={Style.highlight_10god}>{conception_palace_10_god_message.highlight}</span></span>
                    </span>
                  }
                </Grid>
              </Grid>
            </Card>
          </Card>
        </Grid>
      }
    </Animate >
  );
}
